import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton} from '@mui/material';
import {Parada} from '@/domain/entities/Parada';

const typographySx = {
  mt: 0.4,
  fontWeight: 'bold'
};

const codeSx = {
  ...typographySx,
  flexGrow: 1
};

export type DetailHeaderProps = {
  titleKey: string,
  messageKey?: string,
  parada: Partial<Parada>
  onBack: () => void
};

const DetailHeader: FC<DetailHeaderProps> = ({titleKey, messageKey, parada, onBack}) => {
  const {t} = useTranslation();
  return <>
    <Box mb={1} sx={{display:'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, width: '100%'}}>
      <>
        <IconButton sx={{bgcolor: 'lightgrey'}} onClick={onBack} size='small'>
          <ArrowBackIcon fontSize="inherit"/>
        </IconButton>
        <Typography variant='button' sx={typographySx}>
          {t(titleKey)}
        </Typography>
      </>
      <Typography variant='button' sx={codeSx}>
        {parada.code}
      </Typography>
    </Box>
    {messageKey && <Typography>{t(messageKey)}</Typography>}
  </>;
};

export default DetailHeader;
