import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES} from '@/domain/services/Repository';
import {TramType} from '@/domain/entities/TramType';
import {TramTypeRepository} from '@/repository/tramType/TramTypeRepository';

export const getTramTypes = async (): Promise<Array<TramType>> => {
  const repository = inject<TramTypeRepository>(REPOSITORIES.tramType);
  return repository.getAll();
};

export default getTramTypes;