import React, {FC} from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export type optionType = {
  id: string,
  label: string
}

export type SelectInputProps = {
  label: string,
  value: string,
  required: boolean,
  readOnly: boolean,
  options: Array<optionType>
  onChange: (value: string) => void
};

const SelectInput: FC<SelectInputProps> = ({label, value, required, readOnly, options, onChange}) => {

  const handleChange = (e: SelectChangeEvent) => onChange(e.target.value);

  return <FormControl fullWidth required={required} size='small'>
    <InputLabel>{label}</InputLabel>
    <Select value={value} label={label} size='small' onChange={handleChange} disabled={readOnly}>
      {
        options.map(option => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)
      }
    </Select>
  </FormControl>;
};

export default SelectInput;