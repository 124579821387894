import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

//BIKENTA
import DialogPanel from './DialogPanel';
import {useTranslation} from 'react-i18next';

export type LazyLoadingProps = {
  message?: string
};

const contentSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 100,
  width: 300
};

const LazyLoading: FC<LazyLoadingProps> = ({message = 'loading'}) => {
  const {t} = useTranslation();
  
  return <DialogPanel onClose={() => undefined} label={t(message)} isOpen={true}>
    <Box sx={contentSx}>
      <CircularProgress sx={{mb: 2}}/>
    </Box>
  </DialogPanel>;
};


export default LazyLoading;