import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Layout from '@/components/Layout';
import SidePanelContent from './SidePanelContent';
import MainContent from './MainContent';
import LazyLoading from '@/components/LazyLoading';
import ErrorDialog from '@/components/ErrorDialog';


import {API_DEBOUNCE_MS, INITIAL_MAPSTYLE_URL, INITIAL_VIEWPORT, Mode} from '@/config';
import {useAsync} from 'react-use';
import {Viewport} from '@geomatico/geocomponents/types/common';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import pDebounce from 'p-debounce';

import {createParada, editParada, getAvailableGirs} from '@/domain/usecases/paradaUseCases';
import {logout} from '@/domain/usecases/login';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import getManifestData from '@/domain/usecases/getManifestData';
import getAllEntities from '@/domain/usecases/getAllEntities';
import {ParadaLocationEvent} from '@/components/snapping/DrawParadaMode';
import {asFeatures} from '@/services/mapService';
import {Gir} from '@/domain/entities/Gir';
import {LineString, Point, Position} from 'geojson';
import {getGirsByPointOfInterest} from '@/domain/usecases/girUseCases';
import {moveParada} from '@/domain/usecases/moveParada';
import {Tram, TramCreationWithNewGirs} from '@/domain/entities/Tram';
import {TramGeometryEvent} from '@/components/snapping/DrawTramMode';
import {newTram} from '@/domain/usecases/newTram';
import {getTramsByFirstCoordinate, getTramsByLastCoordinate} from '@/domain/usecases/tramUseCases';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Madrid');

type ParadaState = {
  parada: Partial<Parada | ParadaCreation>,
  availableGirs?: Array<Gir>,
  // intersection with one or more trams, available for both middle and endpoints
  intersection?: Point,
  // id of tram if intersection point is cutting a tram in the middle
  cuttingTramId?: number
}

const initialParadaState: ParadaState = {
  parada: {}
};

type TramState = {
  tram: Partial<TramCreationWithNewGirs>,
  availableGirs?: Array<Partial<Gir>>,
  startPointCutTramId?: number
  endPointCutTramId?: number
}

const initialTramState: TramState = {
  tram: {}
};

const Index = () => {
  const [mapStyle] = useState(INITIAL_MAPSTYLE_URL);
  const [viewport, setViewport] = useState<Viewport>(INITIAL_VIEWPORT);
  const [day, setDay] = useState<Dayjs>(dayjs.tz().startOf('day'));
  const [activeMode, setActiveMode] = useState<Mode>(Mode.VIEW);
  const [isSaving, setSaving] = useState(false);
  const [selectedTramIds, setSelectedTramIds] = useState<Array<number>>([]);

  // currently editing
  const [paradaState, setParadaState] = useState<ParadaState>(initialParadaState);
  const [tramState, setTramState] = useState<TramState>(initialTramState);
  const selectedParadaId = useMemo(
    () => 'id' in paradaState.parada && paradaState.parada.id !== undefined ? paradaState.parada.id : undefined,
    [paradaState]);
  const getEntities = useCallback(pDebounce(getAllEntities, API_DEBOUNCE_MS), []);
  const {error, loading, value: entities} = useAsync(() => getEntities(day), [viewport, day, activeMode]);
  const features = useMemo(() => asFeatures(entities), [entities]);
  // TODO TMB-379 handle errors on manifest request
  const {value: manifestData} = useAsync(getManifestData, []);

  const [paradaSearched, setParadaSearched] = useState<Parada | undefined>();

  const resetState = () => {
    if (activeMode === Mode.VIEW) {
      setParadaState(initialParadaState);
      setTramState(initialTramState);  
    }
  };
  useEffect(resetState, [activeMode]);

  const fail = (error: unknown) => {
    window.alert('Something failed :(');
    console.error(error);
    // to not stay on saving/loading forever when it crashes
    setSaving(false);
  };

  const handleParadaSaved = (parada: (Parada | ParadaCreation)) => {
    // under normal operations changing active mode triggers a new loading;
    // saving means loading right afterward so we setSaving(false) after finished loading, below
    setSaving(true);
    const promise = saveParada(parada, activeMode, paradaState.cuttingTramId, paradaState.intersection);
    promise && promise.then(() => setActiveMode(Mode.VIEW)).catch(fail);
  };

  const handleTramSaved = (tram: Tram | TramCreationWithNewGirs) => {
    // under normal operations changing active mode triggers a new loading;
    // saving means loading right afterward so we setSaving(false) after finished loading, below
    if ('newGirs' in tram) {
      setSaving(true);
      const promise = newTram(tram, tram.newGirs, tramState.startPointCutTramId, tramState.endPointCutTramId);
      promise && promise.then(() => setActiveMode(Mode.VIEW)).catch(fail);
    }
  };

  const handleParadaSelected = (selectedParadaId?: number) => {
    // implicit mode changes
    if (selectedParadaId === undefined && activeMode === Mode.EDIT_PARADA) {
      setActiveMode(Mode.VIEW);
    } else if (selectedParadaId !== undefined && activeMode === Mode.VIEW) {
      setActiveMode(Mode.EDIT_PARADA);
    }

    // changing active mode resets state; setParadaState must be after setActiveMode
    setParadaState({
      ...paradaState,
      parada: entities?.parades.find(parada => parada.id === selectedParadaId) || {},
      availableGirs: undefined
    });
  };

  const handleTramGeometryChanged = (event: TramGeometryEvent) => {
    setTramState(previousState => forTramGeometryChange(previousState, event));
    getAvailableGirsForTramCreation(event.geometry, event.firstCuttingTramId, event.secondCuttingTramId)
      .then(availableGirs => setTramState(previousState => ({...previousState, availableGirs})))
      .catch(fail);
  };
  
  useEffect(() => {
    if (paradaState.intersection && !paradaState.cuttingTramId) {
      getGirsByPointOfInterest(paradaState.intersection)
        .then(availableGirs => setParadaState(previousState => ({...previousState, availableGirs})))
        .catch(fail);
    }
  }
  , [paradaState.intersection, paradaState.cuttingTramId]);

  
  useEffect(() => {
    if (selectedParadaId) {
      getAvailableGirs(selectedParadaId)
        .then(availableGirs => setParadaState(previousState => ({...previousState, availableGirs})))
        .catch(fail);
    }
  },
  [selectedParadaId]);

  useEffect(() => {
    if (!loading) setSaving(false);
  }, [loading]);

  const showLoading = isSaving || (loading && !entities);

  const sidePanelContent = <SidePanelContent
    mode={activeMode}
    availableParadaGirs={paradaState.availableGirs}
    availableTramGirs={tramState.availableGirs}
    parada={paradaState.parada}
    paradaTypes={manifestData?.paradaTypes}
    onParadaChange={props => setParadaState(forParadaPropertiesChange(paradaState, props))}
    onParadaSaved={handleParadaSaved}
    onBack={() => setActiveMode(Mode.VIEW)}
    tram={tramState.tram}
    tramTypes={manifestData?.tramTypes}
    onTramChange={props => setTramState(forTramPropertiesChange(tramState, props))}
    onTramSaved={handleTramSaved}
    onGirHover={setSelectedTramIds}
  />;

  const mainContent = <MainContent
    paradaSearched={paradaSearched}
    isDrawParadaEnabled={isDrawParadaEnabled(activeMode, selectedParadaId)}
    isDrawTramEnabled={isDrawTramEnabled(activeMode)}
    features={features}
    mapStyle={mapStyle}
    viewport={viewport}
    onViewportChange={setViewport}
    onParadaSelected={handleParadaSelected}
    selectedParadaId={selectedParadaId}
    selectedTramIds={selectedTramIds}
    onParadaPosition={event => setParadaState(forParadaPositionChange(paradaState, event))}
    onTramGeometry={handleTramGeometryChanged}
  />;

  return <>
    {showLoading && <LazyLoading/>}
    {error && <ErrorDialog message={error.message}/>}
    <Layout
      mode={activeMode}
      parades={entities?.parades}
      sidePanelContent={sidePanelContent}
      mainContent={mainContent}
      day={day}
      onDayChanged={setDay}
      onLogout={logout}
      onModeChanged={(mode) => setActiveMode(mode)}
      onParadeSearched={parada => setParadaSearched(parada)}
    />
  </>;
};

export default Index;

const forParadaPositionChange = (previousState: ParadaState, event: ParadaLocationEvent) => ({
  parada: {
    ...previousState.parada,
    position: event.position,
  },
  cuttingTramId: event.cuttingTramId,
  intersection: event.intersection
});

const forTramGeometryChange = (previousState: TramState, event: TramGeometryEvent) => ({
  tram: {
    ...previousState.tram,
    geometry: event.geometry,
  },
  startPointCutTramId: event.firstCuttingTramId,
  endPointCutTramId: event.secondCuttingTramId
});

const forParadaPropertiesChange = (previousState: ParadaState, parada: Partial<Parada>) => ({
  ...previousState,
  parada: {
    ...previousState.parada,
    ...parada
  }
});

const forTramPropertiesChange = (previousState: TramState, tram: Partial<Tram>) => ({
  ...previousState,
  tram: {
    ...previousState.tram,
    ...tram
  }
});

const isDrawParadaEnabled = (mode: Mode, selectedParadaId?: number) =>
  mode === Mode.CREATE_PARADA
  || (mode === Mode.MOVE_PARADA && selectedParadaId !== undefined);

const isDrawTramEnabled = (mode: Mode) => mode === Mode.CREATE_TRAM;

const saveParada = (
  parada: Parada | ParadaCreation,
  activeMode: Mode,
  cuttingTramId?: number,
  intersection?: Point
) => {
  const tramCut = cuttingTramId && intersection ? {
    tramId: cuttingTramId,
    intersection: intersection
  } : undefined;
  const selectedParadaId = 'id' in parada ? parada.id : undefined;

  switch (activeMode) {
  case Mode.CREATE_PARADA:
    return createParada(parada, tramCut);
  case Mode.EDIT_PARADA:
    if (selectedParadaId !== undefined) {
      return editParada(selectedParadaId, parada as Parada);
    }
    break;
  case Mode.MOVE_PARADA:
    if (selectedParadaId !== undefined) {
      return moveParada({
        paradaId: selectedParadaId,
        newPosition: parada.position,
        startDate: parada.startDate,
        endDate: parada.endDate,
        newGirIds: parada.girIds?.length ? parada.girIds : undefined,
        newTramId: tramCut?.tramId,
        newTramPoint: tramCut?.intersection
      });
    }
    break;
  case Mode.VIEW:
  default:
    // do nothing
  }
};

const getAvailableGirsForTramCreation = async (
  geometry: LineString, startPointCutTramId: number | undefined, endPointCutTramId: number | undefined
): Promise<Array<Partial<Gir>>> => {
  const availableGirs: Array<Partial<Gir>> = [];
  const toGeojson = (coordinate: Position): Point => ({
    type: 'Point',
    coordinates: [coordinate[0], coordinate[1]]
  });

  if (startPointCutTramId !== undefined) {
    availableGirs.push({firstTramId: startPointCutTramId});
  } else {
    const tramsIntersecting = await getTramsByLastCoordinate(toGeojson(geometry.coordinates[0]));
    availableGirs.push(...tramsIntersecting.map(tram => ({firstTramId: tram.id})));
  }

  if (endPointCutTramId !== undefined) {
    availableGirs.push({secondTramId: endPointCutTramId});
  } else {
    const lastCoordinate = geometry.coordinates[geometry.coordinates.length - 1];
    const tramsIntersecting = await getTramsByFirstCoordinate(toGeojson(lastCoordinate));
    availableGirs.push(...tramsIntersecting.map(tram => ({secondTramId: tram.id})));
  }

  return availableGirs;
};
