import {FC} from 'react';
import createDrawParadaMode, {
  DrawParadaOptions,
  EVENT_PARADA_LOCATION,
  ParadaLocationEvent
} from './snapping/DrawParadaMode';
import {AllFeatures} from '@/domain/entities/AllEntities';
import {setupDrawControl} from '@/components/draw/setupDrawControl';
import {SNAPPING_CONFIG} from '@/config';

export type DrawParadaControlProps = {
  snappingData: AllFeatures,
  maxDistanceInMeters: number
  onLocation: (event: ParadaLocationEvent) => void
};

const DrawParadaControl: FC<DrawParadaControlProps> = ({
  snappingData,
  maxDistanceInMeters,
  onLocation
}) => setupDrawControl<DrawParadaOptions, ParadaLocationEvent>(
  'parada', EVENT_PARADA_LOCATION, onLocation,
  createDrawParadaMode, () => ({
    maxDistanceFromIntersectionInMeters: maxDistanceInMeters,
    snappingData: snappingData.trams,
    maxDistanceToSnap: Number.MAX_VALUE,
    ...SNAPPING_CONFIG
  }), [snappingData]);

export default DrawParadaControl;
