import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import SelectInput from './forms/SelectInput';
import CalendarPicker from './forms/CalendarPicker';
import dayjs, {Dayjs} from 'dayjs';
import Box from '@mui/material/Box';
import {Button} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {asTramCreationWithNewGirs, Tram, TramCreationWithNewGirs} from '@/domain/entities/Tram';
import {TramType} from '@/domain/entities/TramType';
import {Gir} from '@/domain/entities/Gir';
import NewGirSelector from '@/components/forms/NewGirSelector';

export type TramCreationProps = {
  tram: Partial<TramCreationWithNewGirs>,
  girs?: Array<Partial<Gir>>,
  availableTypes: TramType[],
  readOnlyProps: boolean, // all properties other than dates
  readOnlyDates: boolean,
  onChange?: (parada: Partial<TramCreationWithNewGirs>) => void
  onSave?: (parada: TramCreationWithNewGirs) => void
};

const getTypeOptions = (availableTypes: TramType[]) => availableTypes.map(availableType => ({
  id: availableType.id + '',
  label: availableType.name
}));

const getSelectedTypeId = (tram: Partial<Tram>, availableTypes: TramType[]): string => {
  if ('typeId' in tram) {
    const type = availableTypes.find(type => type.id === tram.typeId);
    return type ? `${type.id}` : '';
  } else {
    return '';
  }
};

const toDayjs = (date?: Date) => date && dayjs(date);

const TramCreation: FC<TramCreationProps> = ({
  tram,
  girs,
  availableTypes,
  readOnlyProps,
  readOnlyDates,
  onChange,
  onSave
}) => {
  const {t} = useTranslation();

  const changeType = (value: string) => onChange?.({...tram, typeId: Number(value)});
  const changeStartDate = (value?: Dayjs) => onChange?.({...tram, startDate: value?.toDate()});
  const changeEndDate = (value?: Dayjs) => onChange?.({...tram, endDate: value?.toDate()});
  const changeNewGirs = (value?: Array<Partial<Gir>>) => onChange?.({...tram, newGirs: value});

  const selectedRowIds = girs?.filter(gir => tram.newGirs?.includes(gir)).map((_gir, index) => index) || [];

  const entity = asTramCreationWithNewGirs(tram, girs || []);

  return <Box mt={2}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <SelectInput
        label={t('tramType')}
        required={true}
        readOnly={readOnlyProps}
        value={getSelectedTypeId(tram, availableTypes)}
        options={getTypeOptions(availableTypes)}
        onChange={changeType}/>
      <Box mb={2.5} sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1}}>
        <CalendarPicker
          label={t('startDate')}
          readOnly={readOnlyDates}
          required={true}
          value={toDayjs(tram.startDate)}
          onChange={changeStartDate}/>
        <CalendarPicker
          label={t('endDate')}
          readOnly={readOnlyDates}
          required={false}
          value={toDayjs(tram.endDate)}
          onChange={changeEndDate}
        />
      </Box>
    </Box>
    {
      girs ? <NewGirSelector girs={girs} selectedRowIds={selectedRowIds} onChange={changeNewGirs}/> : <></>
    }
    <Button
      startIcon={<SaveIcon/>}
      variant="outlined"
      disabled={!entity}
      onClick={() => entity && onSave?.(entity)}>
      {t('save')}
    </Button>
  </Box>;
};

export default TramCreation;
