import React, {ChangeEvent, FC} from 'react';

import TextField from '@mui/material/TextField';

export type InputFieldProps = {
  label: string,
  value: number | string,
  required: boolean,
  readOnly: boolean,
  type: 'number' | 'text',
  multiline?: boolean,
  onChange: (value: number | string) => void
};

const InputField: FC<InputFieldProps> = ({label, value, required, readOnly, type, multiline=false, onChange}) => {

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(type === 'number' ? Number(e.target.value) : e.target.value);

  return <TextField
    variant='outlined'
    inputProps={{ type: type}}
    fullWidth
    size='small'
    margin='dense'
    multiline={multiline}
    minRows={multiline ? 3 : undefined}
    label={label}
    value={value}
    required={required}
    disabled={readOnly}
    onChange={handleChange}
    sx={{m: 0}}/>;
};

export default InputField;