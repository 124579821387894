export type JwtPayload = {
  name: string,
  email: string
}

export const getUserInformation = (): JwtPayload | undefined => {

  const token = JSON.parse(localStorage.getItem('tmb.token') || '{}');

  if(!token) return;

  const base64Url: string = token.split('.')[1]; // Parte del payload del JWT
  if (!base64Url) return;

  const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window.atob(base64).split('').map(
      c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join('')
  );
  return JSON.parse(jsonPayload);
};
