import React, {FC, useMemo} from 'react';
import NumericInput from './forms/NumericInput';
import {useTranslation} from 'react-i18next';
import TextInput from './forms/TextInput';
import SelectInput, {optionType} from './forms/SelectInput';
import CalendarPicker from './forms/CalendarPicker';
import dayjs, {Dayjs} from 'dayjs';
import Box from '@mui/material/Box';
import {Button, Divider} from '@mui/material';
import {fromPartial, Parada, ParadaCreation} from '@/domain/entities/Parada';
import {ParadaType} from '@/domain/entities/ParadaType';
import {Gir} from '@/domain/entities/Gir';
import SaveIcon from '@mui/icons-material/Save';
import ExistingGirSelector from '@/components/forms/ExistingGirSelector';
import {CODI_DISTRICT} from '@/config';
import {Mode} from '@/config';

export type ParadaDetailProps = {
  mode: Mode,
  parada: Partial<Parada>,
  girs?: Array<Gir>,
  availableTypes: ParadaType[],
  readOnlyProps: boolean, // all properties other than dates
  readOnlyDates: boolean,
  onChange?: (parada: Partial<Parada>) => void
  onSave?: (parada: Parada | ParadaCreation) => void
  onGirHover: (ids: Array<number>) => void
};

const DEFAULT_CITY_CODE = 0;
const DEFAULT_CODE = 0;

const getTypeOptions = (availableTypes: ParadaType[]) => availableTypes.map(availableType => ({
  id: availableType.id + '',
  label: availableType.name
}));

const getSelectedTypeId = (parada: Partial<Parada>, availableTypes: ParadaType[]): string => {
  if ('typeId' in parada) {
    const type = availableTypes.find(type => type.id === parada.typeId);
    return type ? `${type.id}` : '';
  } else {
    return '';
  }
};

const toDayjs = (date?: Date) => date && dayjs(date);

const ParadaDetail: FC<ParadaDetailProps> = ({
  mode,
  parada,
  girs,
  availableTypes,
  readOnlyProps,
  readOnlyDates,
  onChange,
  onSave,
  onGirHover
}) => {
  const {t} = useTranslation();

  const changeName = (value: string) => onChange?.({...parada, name: value});
  const changeType = (value: string) => onChange?.({...parada, typeId: Number(value)});
  const changeCityCode = (value: number) => onChange?.({...parada, cityCode: value});
  const changeDistrictCode = (value: string) => onChange?.({...parada, districtCode: parseFloat(value)});
  const changeCode = (value: number) => onChange?.({...parada, code: value});
  const changeStartDate = (value?: Dayjs) => onChange?.({...parada, startDate: value?.toDate()});
  const changeEndDate = (value?: Dayjs) => onChange?.({...parada, endDate: value?.toDate()});
  const changeGirIds = (value?: Array<number>) => onChange?.({...parada, girIds: value});

  const districtCodiAvailables: optionType[] = useMemo(() => CODI_DISTRICT
    .filter(district => district.cityCode === (parada.cityCode || DEFAULT_CODE))
    .map(({id, label}) => ({id: `${id}`, label})), [parada]);

  const entity = fromPartial(parada);
  return <Box mt={2}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <NumericInput
        label={t('paradaCode')}
        value={parada.code || DEFAULT_CODE}
        required={true}
        readOnly={readOnlyProps}
        onChange={changeCode}/>
      <TextInput
        label={t('paradaName')}
        value={parada.name}
        required={true}
        readOnly={readOnlyProps}
        onChange={changeName}/>
      <SelectInput
        label={t('paradaType')}
        required={true}
        readOnly={readOnlyProps}
        value={getSelectedTypeId(parada, availableTypes)}
        options={getTypeOptions(availableTypes)}
        onChange={changeType}
      />
      <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1}}>
        <NumericInput
          label={t('paradaCityCode')}
          value={parada.cityCode || DEFAULT_CITY_CODE}
          required={true}
          readOnly={readOnlyProps}
          onChange={changeCityCode}/>
        <SelectInput
          label={t('paradaDistrictCode')}
          required={true}
          readOnly={readOnlyProps}
          value={parada.districtCode?.toString() || DEFAULT_CITY_CODE.toString()}
          options={districtCodiAvailables}
          onChange={changeDistrictCode}
        />
      </Box>
      <Box mb={2.5} sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1}}>
        <CalendarPicker
          label={t('startDate')}
          readOnly={readOnlyDates}
          required={true}
          value={mode === Mode.MOVE_PARADA ? toDayjs(new Date()) : toDayjs(parada.startDate)}
          onChange={changeStartDate}
          sx={{width: '100%', flex: 1}}
        />
        <CalendarPicker
          label={t('endDate')}
          readOnly={readOnlyDates}
          required={false}
          value={toDayjs(parada.endDate)}
          onChange={changeEndDate}
          sx={{width: '100%', flex: 1}}
        />
      </Box>
    </Box>

    {
      girs ? <Divider flexItem variant='fullWidth' sx={{my: 2}}/> : <></>
    }
    {
      girs ? <ExistingGirSelector girs={girs} selectedGirIds={parada.girIds} onChange={changeGirIds} onGirHover={onGirHover}/> : <></>
    }
    <Button
      sx={{mt: 2}}
      startIcon={<SaveIcon />}
      variant='outlined'
      disabled={!entity}
      onClick={() => entity && onSave?.(entity)}>
      {t('save')}
    </Button>
  </Box>;
};

export default ParadaDetail;
