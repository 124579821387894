import React, {FC, useMemo} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import styled from '@mui/styles/styled';

import GeomaticoLink from '@/components/GeomaticoLink';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import ParadaDetail from '@/components/ParadaDetail';
import {ParadaType} from '@/domain/entities/ParadaType';
import {Gir} from '@/domain/entities/Gir';
import {Mode} from '@/config';
import DetailHeader from '@/components/DetailHeader';
import TramCreation from '@/components/TramCreation';
import {Tram, TramCreationWithNewGirs} from '@/domain/entities/Tram';
import {TramType} from '@/domain/entities/TramType';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
  padding: '8px'
});

const stackSx = {
  height: '100%',
  overflow: 'hidden'
};

export type SidePanelProps = {
  mode: Mode
  parada: Partial<Parada>
  availableParadaGirs?: Array<Gir>
  paradaTypes?: ParadaType[]
  onParadaChange: (parada: Partial<Parada>) => void
  onParadaSaved: (parada: Parada | ParadaCreation) => void
  tram: Partial<Tram>
  availableTramGirs?: Array<Partial<Gir>>
  tramTypes?: TramType[]
  onTramChange: (tram: Partial<Tram>) => void
  onTramSaved: (tram: Tram | TramCreationWithNewGirs) => void
  onBack: () => void
  onGirHover: (ids: Array<number>) => void
}

const SidePanelContent: FC<SidePanelProps> = (props) => {
  const detailContent = useMemo(() => getContent(props), [props]);
  return <Stack sx={stackSx}>
    <ScrollableContent>
      <DetailHeader
        titleKey={getTitleKey(props.mode)}
        messageKey={getInfoMessageKey(props) ? 'clickStop' : undefined}
        onBack={props.onBack}
        parada={props.parada}
      />
      {detailContent}
    </ScrollableContent>
    <GeomaticoLink/>
  </Stack>;
};

export default SidePanelContent;

const readOnlyProps = (mode: Mode) => mode === Mode.MOVE_PARADA;

const getTitleKey = (mode: Mode): string => {
  switch (mode) {
  case Mode.CREATE_PARADA:
    return 'createStop';
  case Mode.EDIT_PARADA:
    return 'editStop';
  case Mode.MOVE_PARADA:
    return 'moveStop';
  case Mode.CREATE_TRAM:
    return 'createTram';
  case Mode.VIEW:
    return '';
  }
};

const getInfoMessageKey = ({mode, parada}: SidePanelProps) => {
  if (!('id' in parada) && [Mode.EDIT_PARADA, Mode.MOVE_PARADA].includes(mode)) {
    return 'clickStop';
  }

  return undefined;
};

const getContent = ({
  mode,
  parada, paradaTypes, availableParadaGirs, onParadaChange, onParadaSaved,
  tram, tramTypes, availableTramGirs, onTramChange, onTramSaved, onGirHover
}: SidePanelProps) => {
  const paradaDetail = createParadaDetailIfPossible(mode, parada, availableParadaGirs,
    paradaTypes, onParadaChange, onParadaSaved, onGirHover);
  if (paradaDetail) {
    return paradaDetail;
  }

  const tramCreation = createTramCreationIfPossible(
    mode, tram, availableTramGirs, tramTypes, onTramChange, onTramSaved);
  if (tramCreation) {
    return tramCreation;
  }

  return undefined;
};

const createParadaDetailIfPossible = (
  mode: Mode,
  parada: Partial<Parada>,
  availableParadaGirs: Array<Gir> | undefined,
  paradaTypes: Array<ParadaType> | undefined,
  onParadaChange: (parada: Partial<Parada>) => void,
  onParadaSaved: (parada: Parada | ParadaCreation) => void,
  onGirHover: (ids: Array<number>) => void
) => {
  if (!paradaTypes) {
    return undefined;
  }

  if (mode === Mode.CREATE_PARADA ||
    ('id' in parada && [Mode.EDIT_PARADA, Mode.MOVE_PARADA].includes(mode))) {
    return <ParadaDetail
      onGirHover={onGirHover}
      mode={mode}
      parada={parada}
      girs={availableParadaGirs}
      readOnlyProps={readOnlyProps(mode)}
      readOnlyDates={false}
      availableTypes={paradaTypes}
      onChange={onParadaChange}
      onSave={onParadaSaved}/>;
  }

  return undefined;
};
const createTramCreationIfPossible = (
  mode: Mode,
  tram: Partial<Tram>,
  availableTramGirs: Array<Partial<Gir>> | undefined,
  tramTypes: Array<TramType> | undefined,
  onTramChange: (tram: Partial<Tram>) => void,
  onTramSaved: (tram: Tram | TramCreationWithNewGirs) => void
) => {
  if (!tramTypes) {
    return undefined;
  }

  if (mode === Mode.CREATE_TRAM) {
    return <TramCreation
      tram={tram}
      girs={availableTramGirs}
      readOnlyProps={readOnlyProps(mode)}
      readOnlyDates={false}
      availableTypes={tramTypes}
      onChange={onTramChange}
      onSave={onTramSaved}/>;
  }

  return undefined;
};
