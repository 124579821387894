import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES, Repository} from '@/domain/services/Repository';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import {OpRepository} from '@/repository/op/OpRepository';
import {Point} from 'geojson';
import {Gir} from '@/domain/entities/Gir';
import {ParadaRepository} from '@/repository/parada/ParadaRepository';

export const getParades = async (date: Date): Promise<Parada[]> => {
  const repository = inject<Repository<Parada, ParadaCreation>>(REPOSITORIES.parada);
  return repository.list(date);
};

export const createParada = async (parada: ParadaCreation, tramCut?: { intersection: Point, tramId: number }) => {
  if (tramCut) {
    const repository = inject<OpRepository>(REPOSITORIES.op);
    return await repository.newParada({
      ...parada,
      tramPoint: tramCut.intersection,
      tramId: tramCut.tramId
    });
  } else {
    const repository = inject<Repository<Parada, ParadaCreation>>(REPOSITORIES.parada);
    return await repository.create(parada);
  }
};

export const editParada = async (paradaId: number, parada: Parada): Promise<Parada> => {
  const repository = inject<Repository<Parada, ParadaCreation>>(REPOSITORIES.parada);
  return await repository.put(paradaId, parada);
};


export const getAvailableGirs = async (paradaId: number): Promise<Array<Gir>> => {
  const repository = inject<ParadaRepository>(REPOSITORIES.parada);
  return repository.getAvailableGirs(paradaId);
};
