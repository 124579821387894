import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import {ParadaDto, ParadaDtoCreation} from '@/repository/parada/ParadaDto';

export const toDto = (domain: Parada): ParadaDto => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString(),
  };
};

export const toDtoCreation = (domain: ParadaCreation): ParadaDtoCreation => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString(),
  };
};

export const fromDto = (dto: ParadaDto): Parada => {
  return {
    ...dto,
    startDate: new Date(dto.startDate),
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
  };
};