import {ParadaType} from '@/domain/entities/ParadaType';
import getParadaTypes from '@/domain/usecases/getParadaTypes';
import {TramType} from '@/domain/entities/TramType';
import getTramTypes from '@/domain/usecases/getTramTypes';

export type ManifestData = {
  paradaTypes: Array<ParadaType>
  tramTypes: Array<TramType>
}

/**
 * Return all data that can be loaded just once on startup and is not meant to change.
 */
const getManifestData = async (): Promise<ManifestData> => {
  return {
    paradaTypes: await getParadaTypes(),
    tramTypes: await getTramTypes()
  };
};

export default getManifestData;