import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton} from '@mui/material';

const typographySx = {
  mt: 0.4,
  fontWeight: 'bold'
};

export type ModeHeaderProps = {
  titleKey: string,
  showMessage: boolean,
  onBack: () => void
};

const ModeHeader: FC<ModeHeaderProps> = ({titleKey, showMessage, onBack}) => {
  const {t} = useTranslation();
  return <>
    <Box mb={1} sx={{display:'flex', flexDirection:'row', alignItems: 'center', gap: 1}}>
      <IconButton sx={{bgcolor: 'lightgrey'}} onClick={onBack} size='small'>
        <ArrowBackIcon fontSize="inherit"/>
      </IconButton>
      <Typography variant='button' sx={typographySx}>
        {t(titleKey)}
      </Typography>
    </Box>
    {showMessage && <Typography>{t('click_bus_stop')}</Typography>}
  </>;
};

export default ModeHeader;
