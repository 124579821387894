import {Parada} from '@/domain/entities/Parada';
import Http from '@/domain/services/Http';
import {ParadaDto} from '@/repository/parada/ParadaDto';
import {toMoveParadaRequestDto, toNewParadaRequestDto, toNewTramRequestDto} from './opMappers';
import {fromDto as fromParadaDto} from '../parada/paradaMappers';
import {fromDto as fromTramDto} from '../tram/tramMappers';
import {MoveParadaRequestDto, NewParadaRequestDto, NewTramRequestDto} from '@/repository/op/OperationsDto';
import {MoveParadaRequest, NewParadaRequest, NewTramRequest} from '@/domain/entities/Operations';
import {Tram} from '@/domain/entities/Tram';
import {TramDto} from '@/repository/tram/TramDto';


export const PATH = 'op';

export interface OpRepository {
  newParada: (request: NewParadaRequest) => Promise<Parada>;
  moveParada: (request: MoveParadaRequest) => Promise<Parada>;
  newTram: (request: NewTramRequest) => Promise<Tram>;
}

export const OpRepository = (apiBaseUrl: URL | string, http: Http): OpRepository => ({
  newParada: (request) =>
    http<ParadaDto, NewParadaRequestDto>(new URL(`${PATH}/new-parada`, apiBaseUrl), {
      method: 'POST',
      body: toNewParadaRequestDto(request)
    })
      .then(parada => fromParadaDto(parada)),
  moveParada: (request) => http<ParadaDto, MoveParadaRequestDto>(
    new URL(`${PATH}/move-parada`, apiBaseUrl), {
      method: 'POST',
      body: toMoveParadaRequestDto(request)
    })
    .then(parada => fromParadaDto(parada)),
  newTram: (request) => http<TramDto, NewTramRequestDto>(
    new URL(`${PATH}/new-tram`, apiBaseUrl), {
      method: 'POST',
      body: toNewTramRequestDto(request)
    })
    .then(tram => fromTramDto(tram)),
});
