import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import {SxProps} from '@mui/material';

//UTILS
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import {Dayjs} from 'dayjs';
import {useTranslation} from 'react-i18next';
import 'dayjs/locale/ca';
import 'dayjs/locale/es';
import i18n from 'i18next';

export type DatePickerProps = {
  label: string,
  readOnly: boolean,
  required: boolean,
  value?: Dayjs,
  onChange: (value?: Dayjs) => void,
  sx?: SxProps
};

const CalendarPicker: FC<DatePickerProps> = ({label, required, readOnly, value, onChange, sx}) => {

  const {t} = useTranslation();
  const lang = i18n.language;

  const calendarSx: SxProps = {
    ...sx
  };

  return <Box>
    <FormControl fullWidth required={required}>
      <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{todayButtonLabel: t('today')}} adapterLocale={lang}>
        <DatePicker
          value={value}
          disabled={readOnly}
          label={label}
          slotProps={{
            popper: {
              sx: { opacity: 0.95 },
            },
            layout: {
              sx: { '& .MuiPaper-root': { opacity: 0.5 } }
            },
            actionBar: {
              actions: ['today'],
              sx: {
                justifyContent: 'center',
                '& .MuiButtonBase-root': {
                  width: '100px',
                  border: '1px solid red',
                  mb: 1
                }
              }
            },
            textField: { size: 'small' },
            field: { clearable: false },
          }}
          sx={calendarSx}
          onChange={(day) => onChange(day == null ? undefined : day)}
        />
      </LocalizationProvider>
    </FormControl>
  </Box>;
};

export default CalendarPicker;
