import {Point} from 'geojson';
import {Gir} from '@/domain/entities/Gir';
import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES} from '@/domain/services/Repository';
import {GirRepository} from '@/repository/gir/girRepository';


export const getGirsByPointOfInterest = async (point: Point): Promise<Array<Gir>> => {
  const repository = inject<GirRepository>(REPOSITORIES.gir);

  return repository.list({pointOfInterest: point});
};

export const getGirsByTramId = async (tramId: number[]): Promise<Array<Gir>> => {
  const repository = inject<GirRepository>(REPOSITORIES.gir);

  return repository.list({tramIds: tramId});
};