import Http from '@/domain/services/Http';
import {ParadaTypeDto} from '@/repository/paradaType/ParadaTypeDto';
import {fromDto} from '@/repository/paradaType/paradaTypeMappers';
import {ParadaType} from '@/domain/entities/ParadaType';


export const PATH = 'parada-types';

export interface ParadaTypeRepository {
  getAll: () => Promise<ParadaType[]>
}

export const ParadaTypeRepository = (apiBaseUrl: URL | string, http: Http): ParadaTypeRepository => ({
  getAll: () => http<Array<ParadaTypeDto>>(new URL(PATH, apiBaseUrl))
    .then(paradaTypes => paradaTypes.map(fromDto))
});
