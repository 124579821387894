import React, {FC} from 'react';
import {MINISIDEPANEL_CONFIG, Mode} from '@/config';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import styled from '@mui/system/styled';
import ToggleButtonGroup, {toggleButtonGroupClasses} from '@mui/material/ToggleButtonGroup';
import {Divider} from '@mui/material';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

//TYPES
export type ModePickerProps = {
  sidePanelContent: React.ReactElement,
  miniSidePanelSelectedActionId: Mode,
  onModeChanged: (id: Mode ) => void,
  onLogout: () => void
};

const ModePicker: FC<ModePickerProps> = ({sidePanelContent, miniSidePanelSelectedActionId, onModeChanged, onLogout}) => {

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      border: 0,
      borderRadius: theme.shape.borderRadius,
      [`&.${toggleButtonGroupClasses.disabled}`]: {
        border: 0,
      },
    }
  }));

  const handleActionClick = (id: string) => {
    if (Object.values(Mode).includes(id as Mode)) {
      const mode: Mode = id as Mode;
      if (miniSidePanelSelectedActionId === mode && sidePanelContent) {
        onModeChanged(Mode.VIEW);
      } else {
        onModeChanged(mode);
      }
    }
  };

  return <Paper elevation={0}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      outline: '1px solid lightgrey',
      borderRadius: '8px',
      opacity: 0.95,
      position: 'absolute',
      top: 'calc(32px + 50%)',
      transform: 'translateY(-50%)',
      left: 8
    }}
  >
    <StyledToggleButtonGroup orientation='vertical' value={miniSidePanelSelectedActionId} exclusive size='small'
      onChange={(e, id) => handleActionClick(id)}
    >
      {
        MINISIDEPANEL_CONFIG.map(({id, label, content}) => <ToggleButton value={id} key={id}>
          <Tooltip placement='right' title={label} key={id}>
            {content}
          </Tooltip>
        </ToggleButton>)
      }
    </StyledToggleButtonGroup>
    <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
    <StyledToggleButtonGroup orientation='vertical' sx={{bgcolor: 'white', }} value={miniSidePanelSelectedActionId} exclusive size='small'
      onChange={(e, id) => handleActionClick(id)}
    >
      <ToggleButton value='settings'>
        <SettingsRoundedIcon/>
      </ToggleButton>
    </StyledToggleButtonGroup>
    <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
    <StyledToggleButtonGroup orientation='vertical' sx={{bgcolor: 'white', mb: 1 }} value={miniSidePanelSelectedActionId} exclusive size='small'
      onChange={(e, id) => handleActionClick(id)}
    >
      <ToggleButton value='settings'>
        <AccountCircleIcon onClick={onLogout}/>
      </ToggleButton>
    </StyledToggleButtonGroup>
  </Paper>;
};
export default ModePicker;