import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Layout from '@/components/Layout';
import SidePanelContent from './SidePanelContent';
import MainContent from './MainContent';
import LazyLoading from '@/components/LazyLoading';
import ErrorDialog from '@/components/ErrorDialog';


import {API_DEBOUNCE_MS, INITIAL_MAPSTYLE_URL, INITIAL_VIEWPORT, Mode} from '@/config';
import {useAsync} from 'react-use';
import {Viewport} from '@geomatico/geocomponents/types/common';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import pDebounce from 'p-debounce';

import {createParada, editParada, getAvailableGirs} from '@/domain/usecases/paradaUseCases';
import {logout} from '@/domain/usecases/login';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import getManifestData from '@/domain/usecases/getManifestData';
import getAllEntities from '@/domain/usecases/getAllEntities';
import {ParadaLocationEvent} from '@/components/snapping/DrawParadaMode';
import {asFeatures} from '@/services/mapService';
import {Gir} from '@/domain/entities/Gir';
import {Point} from 'geojson';
import {getGirsByPointOfInterest} from '@/domain/usecases/girUseCases';
import {moveParada} from '@/domain/usecases/moveParada';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Madrid');

type ParadaState = {
  parada: Partial<Parada | ParadaCreation>,
  // intersection with one or more trams, available for both middle and endpoints
  intersection?: Point,
  // id of tram if intersection point is cutting a tram in the middle
  cuttingTramId?: number
}

const initialParadaState: ParadaState = {
  parada: {}
};

const Index = () => {
  const [mapStyle, setMapStyle] = useState(INITIAL_MAPSTYLE_URL);
  const [viewport, setViewport] = useState<Viewport>(INITIAL_VIEWPORT);
  const [day, setDay] = useState<Dayjs>(dayjs.tz().startOf('day'));
  const [activeMode, setActiveMode] = useState<Mode>(Mode.VIEW);
  const [isSaving, setSaving] = useState(false);
  
  // currently editing
  const [paradaState, setParadaState] = useState<ParadaState>(initialParadaState);
  const [availableGirs, setAvailableGirs] = useState<Array<Gir>>();
  const selectedParadaId = useMemo(
    () => 'id' in paradaState.parada && paradaState.parada.id !== undefined ? paradaState.parada.id : undefined,
    [paradaState]);

  const getEntities = useCallback(pDebounce(getAllEntities, API_DEBOUNCE_MS), []);
  const {error, loading, value: entities} = useAsync(() => getEntities(day), [viewport, day, activeMode]);
  const features = useMemo(() => asFeatures(entities), [entities]);
  // TODO TMB-379 handle errors on manifest request
  const {value: manifestData} = useAsync(getManifestData, []);

  const resetState = () => {
    setParadaState(initialParadaState);
    setAvailableGirs(undefined);
  };
  useEffect(resetState, [activeMode]);

  const fail = () => {
    window.alert('Something failed :(');
    // to not stay on saving/loading forever when it crashes
    setSaving(false);
  };

  const handleParadaSaved = (parada: (Parada | ParadaCreation)) => {
    // under normal operations changing active mode triggers a new loading;
    // saving means loading right afterward so we setSaving(false) after finished loading, below
    setSaving(true);
    const promise = saveParada(parada, activeMode, paradaState.cuttingTramId, paradaState.intersection);
    promise && promise.then(() => setActiveMode(Mode.VIEW)).catch(fail);
  };

  const handleParadaSelected = (selectedParadaId?: number) => {
    // implicit mode changes
    if (selectedParadaId === undefined && activeMode === Mode.EDIT_BUS_STOP) {
      setActiveMode(Mode.VIEW);
    } else if (selectedParadaId !== undefined && activeMode === Mode.VIEW) {
      setActiveMode(Mode.EDIT_BUS_STOP);
    }

    // changing active mode resets state; setParadaState must be after setActiveMode
    setParadaState({
      ...paradaState,
      parada: entities?.parades.find(parada => parada.id === selectedParadaId) || {},
    });
    setAvailableGirs(undefined);
  };

  // update available girs
  const updateAvailableGirs = (promise?: Promise<Array<Gir>>) => updateState(promise, setAvailableGirs, fail);
  useEffect(
    () => updateAvailableGirs(paradaState.intersection && getGirsByPointOfInterest(paradaState.intersection))
    , [paradaState.intersection]);
  useEffect(
    () => updateAvailableGirs(selectedParadaId ? getAvailableGirs(selectedParadaId) : undefined),
    [selectedParadaId]);

  useEffect(() => {
    if (!loading) setSaving(false);
  }, [loading]);

  const showLoading = isSaving || (loading && !entities);

  const sidePanelContent = <SidePanelContent
    mode={activeMode}
    parada={paradaState.parada}
    availableGirs={availableGirs}
    paradaTypes={manifestData?.paradaTypes}
    onParadaChange={props => setParadaState(forPropertiesChange(paradaState, props))}
    onParadaSaved={handleParadaSaved}
    onBack={() => setActiveMode(Mode.VIEW)}
  />;

  const mainContent = <MainContent
    isDrawEnabled={isDrawEnabled(activeMode, selectedParadaId)}
    features={features}
    mapStyle={mapStyle}
    onMapStyleChange={setMapStyle}
    viewport={viewport}
    onViewportChange={setViewport}
    onParadaSelected={handleParadaSelected}
    selectedParadaId={selectedParadaId}
    onParadaPosition={event => setParadaState(forPositionChange(paradaState, event))}
  />;

  return <>
    {showLoading && <LazyLoading/>}
    {error && <ErrorDialog message={error.message}/>}
    <Layout
      mode={activeMode}
      sidePanelContent={sidePanelContent}
      mainContent={mainContent}
      day={day}
      onDayChanged={setDay}
      onLogout={logout}
      onModeChanged={(mode) => setActiveMode(mode)}
    >
    </Layout>
  </>;
};

export default Index;

const forPositionChange = (previousState: ParadaState, event: ParadaLocationEvent) => ({
  parada: {
    ...previousState.parada,
    position: event.position,
  },
  cuttingTramId: event.cuttingTramId,
  intersection: event.intersection
});

const forPropertiesChange = (previousState: ParadaState, parada: Partial<Parada>) => ({
  ...previousState,
  parada: {
    ...previousState.parada,
    ...parada
  }
});

const isDrawEnabled = (mode: Mode, selectedParadaId?: number) =>
  mode === Mode.CREATE_BUS_STOP
  || (mode === Mode.MOVE_BUS_STOP && selectedParadaId !== undefined);

const updateState = <T, >(
  promise: Promise<T | undefined> | undefined, setter: (t?: T) => void, catchCallback: () => void
) => {
  if (promise) {
    promise.then(setter).catch(catchCallback);
  } else {
    setter(undefined);
  }
};

const saveParada = (
  parada: Parada | ParadaCreation,
  activeMode: Mode,
  cuttingTramId?: number,
  intersection?: Point
) => {
  const tramCut = cuttingTramId && intersection ? {
    tramId: cuttingTramId,
    intersection: intersection
  } : undefined;
  const selectedParadaId = 'id' in parada ? parada.id : undefined;

  switch (activeMode) {
  case Mode.CREATE_BUS_STOP:
    return createParada(parada, tramCut);
  case Mode.EDIT_BUS_STOP:
    if (selectedParadaId !== undefined) {
      return editParada(selectedParadaId, parada as Parada);
    }
    break;
  case Mode.MOVE_BUS_STOP:
    if (selectedParadaId !== undefined) {
      return moveParada({
        paradaId: selectedParadaId,
        newPosition: parada.position,
        startDate: parada.startDate,
        endDate: parada.endDate,
        newGirIds: parada.girIds.length ? parada.girIds : undefined,
        newTramId: tramCut?.tramId,
        newTramPoint: tramCut?.intersection
      });
    }
    break;
  case Mode.VIEW:
  default:
    // do nothing
  }
};
