import React, {FC} from 'react';
import {Gir} from '@/domain/entities/Gir';
import GirSelector from '@/components/forms/GirSelector';
import {useTranslation} from 'react-i18next';


export type NewGirSelectorProps = {
  girs: Array<Partial<Gir>>,
  selectedRowIds?: Array<number>
  onChange: (girs: Array<Partial<Gir>>) => void
  onGirHover?: (ids: Array<number>) => void
};

/**
 * Gir selector where some of the girs might not yet exist (for example, for operations that involve creating
 * trams and girs at the same time.
 */
const NewGirSelector: FC<NewGirSelectorProps> = ({girs, selectedRowIds, onChange, onGirHover}) => {
  const {t} = useTranslation();

  const rows = girs.map((gir, index) => ({
    id: index,
    girId: gir.id !== undefined ? gir.id : t('girSelectorNew'),
    firstTramId: gir.firstTramId !== undefined ? gir.firstTramId : t('girSelectorNew'),
    secondTramId: gir.secondTramId !== undefined ? gir.secondTramId : t('girSelectorNew'),
  }));

  const handleChange = (ids: number[]) => {
    const selectedGirs = girs.filter((_gir, index) => ids.map(Number).includes(index));
    onChange(selectedGirs);
  };

  return <GirSelector rows={rows} selectedRowIds={selectedRowIds} onChange={handleChange} onGirHover={onGirHover}/>;
};

export default NewGirSelector;