import MapboxDraw, {DrawCustomModeThis, DrawLineString, DrawPoint, MapMouseEvent} from '@mapbox/mapbox-gl-draw';
import {Feature, LineString, Position} from 'geojson';
import {
  buildMode,
  resetFeature,
  snapToTram,
  TmbDrawModeOptions,
  TmbDrawModeState
} from '@/components/snapping/snapping';


export type DrawTramOptions = TmbDrawModeOptions<LineString>;

export const EVENT_TRAM_GEOMETRY = 'draw.tram.geometry';

export type TramGeometryEvent = {
  geometry: LineString,
  firstCuttingTramId?: number,
  secondCuttingTramId?: number,
}

type DrawTramState = TmbDrawModeState<LineString, DrawTramOptions> & {
  tramGeometry: DrawLineString
  // The next point to be drawn
  nextPoint: DrawPoint,
  nextSegment: DrawLineString,
}

const DRAW_NEXT_POINT_ID = 'nextPoint';
const DRAW_NEXT_SEGMENT_ID = 'nextSegment';
const DRAW_TRAM_GEOMETRY_ID = 'tramGeometry';

// we need to persist some information in the tram geometry properties so we could use them
// if the snapping features change in order to set up the state again
const PROP_DONE = 'done';
const PROP_FIRST_CUTTING_TRAM_ID = 'firstCuttingTramId';
const PROP_SECOND_CUTTING_TRAM_ID = 'secondCuttingTramId';

const {doubleClickZoom} = MapboxDraw.lib;

export default () => buildMode<LineString, DrawTramOptions, DrawTramState>({
  drawCustomMode: MapboxDraw.modes.draw_line_string,
  initializeState,
  onClick,
  onMouseMove
});

const initializeState = (
  customMode: DrawCustomModeThis, options: DrawTramOptions, filteredFeatures: Feature<LineString>[]
): DrawTramState => {
  doubleClickZoom.disable(customMode);
  return {
    filteredSnappingData: filteredFeatures,
    tramGeometry: initializeTramGeometry(customMode, options, filteredFeatures),
    nextPoint: resetFeature<DrawPoint>(customMode, DRAW_NEXT_POINT_ID, 'Point'),
    nextSegment: resetFeature<DrawLineString>(customMode, DRAW_NEXT_SEGMENT_ID, 'LineString'),
    options
  };
};

const initializeTramGeometry = (
  customMode: DrawCustomModeThis, options: DrawTramOptions, filteredFeatures: Feature<LineString>[]
): DrawLineString => {
  const tramGeometry = customMode.getFeature(DRAW_TRAM_GEOMETRY_ID) as DrawLineString;
  if (!tramGeometry || !tramGeometry.getCoordinates() || !tramGeometry.getCoordinates().length) {
    // if there's no line with at least one coordinate, reset new tram geometry
    return resetFeature<DrawLineString>(customMode, DRAW_TRAM_GEOMETRY_ID, 'LineString');
  }

  const isCoordinateValid = (coordinate: Position, expectedCuttingTramId: number) => {
    const snappedTramData = snapToTram(filteredFeatures, options, coordinate[0], coordinate[1]);
    if (expectedCuttingTramId === undefined) {
      // if it wasn't cutting, it shouldn't snap to a middle point now
      return snappedTramData?.cuttingTramId === undefined;
    } else {
      // if it was cutting, it should do as well now, within a small distance
      return snappedTramData?.cuttingTramId === expectedCuttingTramId
        && snappedTramData.distanceToTram < 5e-6;
    }
  };

  const firstCoord = tramGeometry.coordinates[0];
  const lastCoord = tramGeometry.coordinates[tramGeometry.coordinates.length - 1];
  const firstCuttingTramId = tramGeometry.properties?.[PROP_FIRST_CUTTING_TRAM_ID];
  const secondCuttingTramId = tramGeometry.properties?.[PROP_SECOND_CUTTING_TRAM_ID];
  return (
    isCoordinateValid(firstCoord, firstCuttingTramId) &&
    isCoordinateValid(lastCoord, secondCuttingTramId)
  )
    ? tramGeometry
    : resetFeature<DrawLineString>(customMode, DRAW_TRAM_GEOMETRY_ID, 'LineString');
};

const onClick = (drawCustomMode: DrawCustomModeThis, state: DrawTramState) => {
  const addCoordinate = () => {
    const nextCoordinate = state.nextPoint.getCoordinate();

    // trigger event on "double" click (roughly same position even if some time passed)
    const lastCoordinate = state.tramGeometry.coordinates
      ? state.tramGeometry.coordinates[state.tramGeometry.coordinates.length - 1]
      : undefined;
    if (lastCoordinate &&
      Math.abs(lastCoordinate[0] - nextCoordinate[0]) < 1e-5 &&
      Math.abs(lastCoordinate[1] - nextCoordinate[1]) < 1e-5
    ) {
      state.tramGeometry.setProperty(PROP_DONE, true);
      if (state.snappedTramData) {
        state.tramGeometry.setProperty(PROP_SECOND_CUTTING_TRAM_ID, state.snappedTramData.cuttingTramId);
      }

      const event: TramGeometryEvent = {
        geometry: {
          type: state.tramGeometry.type,
          coordinates: state.tramGeometry.coordinates
        },
        firstCuttingTramId: state.tramGeometry.properties?.[PROP_FIRST_CUTTING_TRAM_ID],
        secondCuttingTramId: state.tramGeometry.properties?.[PROP_SECOND_CUTTING_TRAM_ID],
      };
      drawCustomMode.map.fire(EVENT_TRAM_GEOMETRY, event);
    } else {
      state.tramGeometry.addCoordinate(state.tramGeometry.coordinates.length, nextCoordinate[0], nextCoordinate[1]);
      if (state.tramGeometry.coordinates.length === 1) {
        state.tramGeometry.setProperty(PROP_DONE, false);
        state.tramGeometry.setProperty(PROP_FIRST_CUTTING_TRAM_ID, state.snappedTramData?.cuttingTramId);
      }
    }
  };

  if (isDone(state)) {
    // if clicking when another tram geometry is already done, start a new one
    state.nextSegment = resetFeature<DrawLineString>(drawCustomMode, DRAW_NEXT_SEGMENT_ID, 'LineString');
    state.tramGeometry = resetFeature<DrawLineString>(drawCustomMode, DRAW_TRAM_GEOMETRY_ID, 'LineString');
    addCoordinate();
  } else {
    addCoordinate();
  }
};

const onMouseMove = (drawCustomMode: DrawCustomModeThis, state: DrawTramState, event: MapMouseEvent) => {
  const snappedTramData = snapToTram(state.filteredSnappingData, state.options, event.lngLat.lng, event.lngLat.lat);
  state.snappedTramData = snappedTramData;
  if (!snappedTramData) {
    state.nextPoint.updateCoordinate(event.lngLat.lng, event.lngLat.lat);
  } else {
    state.nextPoint.updateCoordinate(snappedTramData.position.longitude, snappedTramData.position.latitude);
  }

  // do not update the tram geometry if it's marked as done
  if (state.tramGeometry.coordinates.length > 0 && !isDone(state)) {
    const lastCoordinate = state.tramGeometry.coordinates[state.tramGeometry.coordinates.length - 1];
    state.nextSegment.updateCoordinate('0', lastCoordinate[0], lastCoordinate[1]);
    state.nextSegment.updateCoordinate('1', state.nextPoint.getCoordinate()[0], state.nextPoint.getCoordinate()[1]);
  }
};

const isDone = (state: DrawTramState) => state.tramGeometry.properties?.[PROP_DONE] === true;
