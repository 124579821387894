import Http from '@/domain/services/Http';
import {Repository} from '@/domain/services/Repository';
import {Tram, TramCreation} from '@/domain/entities/Tram';
import {fromDto, toDto} from './tramMappers';
import {TramDto, TramDtoCreation} from '@/repository/tram/TramDto';


export const PATH = 'trams';

export const TramRepository = (apiBaseUrl: URL | string, http: Http): Repository<Tram, TramCreation> => ({
  list: (date: Date) =>
    http<Array<TramDto>>(new URL(`${PATH}?date=${date.toISOString()}`, apiBaseUrl))
      .then(trams => trams.map(tram => fromDto(tram))),
  create: (tram: TramCreation) =>
    http<TramDto, TramDtoCreation>(new URL(PATH, apiBaseUrl), {
      method: 'POST',
      body: toDto(tram)
    })
      .then(fromDto),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  put: (tramId: number, tram: Tram) => {
    throw new Error('Not implemented');
  }
});
