import React, {FC} from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {Gir} from '@/domain/entities/Gir';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';


const StyledDataGrid = styled(DataGrid)(() => ({
  border: 0,
  '& .MuiDataGrid-columnHeaderCheckbox': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '12px',
    textTransform: 'uppercase'
  },
  '& .MuiDataGrid-cell': {
    //fontSize: '12px',
    textAlign: 'center'
  },

}));

export type GirSelectorProps = {
  girs: Array<Gir>,
  selectedGirIds?: number[]
  onChange: (girIds: number[]) => void,
  multiple: boolean
};



const GirSelector: FC<GirSelectorProps> = ({girs, selectedGirIds, onChange, multiple = false}) => {
  const {t} = useTranslation();
  
  const columns: GridColDef[] = [
    { field: 'id', headerName: t('ID'), headerAlign: 'center', width: 80, align: 'center', sortable: false },
    { field: 'firstTramId', headerName: t('first_tram_id'), headerAlign: 'center', align: 'center', width: 130, sortable: false },
    { field: 'secondTramId', headerName: t('second_tram_id'), headerAlign: 'center', align: 'center', width: 130, sortable: false },
  ];

  const handleSelectionChange = (ids: GridRowSelectionModel) => {
    if (multiple || ids.length <= 1) {
      onChange(ids as number[]);
      return;
    }
    // dataGrid returns last selected as last element
    const newSelected = ids[ids.length - 1] as number;
    onChange([newSelected]);
  };

  return <Box mb={1.5}>
    <Typography variant='overline' sx={{fontWeight: 'bold'}}>{t('turns')}</Typography>
    <StyledDataGrid
      getDetailPanelContent={(p) => <Typography sx={{outline: '2px solid red'}}>{p}</Typography>}
      rows={girs}
      columns={columns}
      pageSizeOptions={[5, 10]}
      density='compact'
      hideFooter
      disableColumnMenu
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={handleSelectionChange}
      rowSelectionModel={selectedGirIds}
    /> 
  </Box>;
};

export default GirSelector;