import React, {FC, useState} from 'react';

import Box from '@mui/material/Box';

import ResponsiveHeader from '@geomatico/geocomponents/Layout/ResponsiveHeader';
import SidePanel from '@geomatico/geocomponents/Layout/SidePanel';

import {
  DRAWER_WIDTH,
  Mode, RESPONSIVE_HEADER_HEIGHT, RESPONSIVE_HEADER_WIDTH,
  SM_BREAKPOINT
} from '@/config';
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';

import LogoTMBNegativo from '../../resources/logos/LogoTMBNegativo';
import CalendarPicker from './forms/CalendarPicker';
import {Dayjs} from 'dayjs';

import { Collapse, Paper, } from '@mui/material';
import ModePicker from '@/components/forms/ModePicker';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export type MainProps = {
  widescreen: boolean,
  isleftdraweropen: boolean
}

const Main = styled(Box)<MainProps>(() => ({
  flexGrow: 1,
  padding: 0,
  position: 'relative',
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  overflow: 'hidden',
})) as React.ElementType;

const responsiveHeaderSx = {
  '&.MuiAppBar-root': {
    position: 'absolute',
    top: 8,
    left: 8,
    zIndex: 1500,
    width: RESPONSIVE_HEADER_WIDTH,
    borderRadius: '8px',

  },
  '& .ResponsiveHeader-toolbar': {
    borderRadius: '8px',
    border: '4px solid #c90005',
    bgcolor: 'primary.main',
    height: RESPONSIVE_HEADER_HEIGHT + 'px'
  },
  '& .MuiPaper-root': {
    borderRadius: '8px'
  }
};

export type LayoutProps = {
  mainContent: React.ReactElement,
  sidePanelContent: React.ReactElement,
  mode: Mode,
  day: Dayjs,
  onDayChanged: (day: Dayjs) => void,
  onLogout: () => void,
  onModeChanged: (mode: Mode) => void,
};

const Layout: FC<LayoutProps> = ({
  mainContent,
  sidePanelContent,
  mode,
  day,
  onDayChanged,
  onLogout,
  onModeChanged
}) => {
  const widescreen = useMediaQuery(`@media (min-width:${SM_BREAKPOINT}px)`, {noSsr: true});

  const [isCollapseCalendar, setCollapseCalendar] = useState(true);

  const sidePanelSx = {
    '& .MuiPaper-root': {
      border: '1px solid lightgrey',
      borderRadius: '8px',
      opacity: 0.95,
      position: 'absolute',
      top: 'calc(32px + 50%)',
      left: 8,
      transform: 'translateY(-50%)',
      height: 'auto'
    }
  };

  const calendar = (
    <Paper sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', left: RESPONSIVE_HEADER_WIDTH, top: 16, zIndex: 500, p: 0.5, pl: 1 }} elevation={4}>
      <CalendarPicker
        value={day} label="" readOnly={false} required={true}
        onChange={day => day && day.isValid() && onDayChanged(day)}
        sx={{width: '200px', opacity: 0.95, pl: 0.5}}
      />
      {
        isCollapseCalendar
          ? <KeyboardArrowLeftIcon onClick={() => setCollapseCalendar(!isCollapseCalendar)}/>
          : <KeyboardArrowRightIcon onClick={() => setCollapseCalendar(!isCollapseCalendar)}/>
      }
    </Paper>
  );

  return <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, height: '100vh', width: '100vw'}}>
    
    <Main>
      <ResponsiveHeader
        title='Bus Inventory Admin'
        logo={<LogoTMBNegativo width='80%' style={{marginLeft: 10}}/>}
        isStartIconCloseable={false}
        sx={responsiveHeaderSx}
      />
      {calendar}
      <Collapse in={isCollapseCalendar} orientation='horizontal' collapsedSize={40}>{calendar}</Collapse>
      {mainContent}
      {
        mode === Mode.VIEW &&
          <ModePicker
            sidePanelContent={sidePanelContent}
            miniSidePanelSelectedActionId={mode}
            onModeChanged={onModeChanged}
            onLogout={onLogout}
          />
      }
      {
        sidePanelContent && mode !== Mode.VIEW && <SidePanel
          drawerWidth={DRAWER_WIDTH + 'px'}
          anchor="left"
          isOpen={true}
          widescreen={widescreen}
          sx={sidePanelSx}
        >
          {sidePanelContent}
        </SidePanel>
      }
    </Main>

  </Box>;
};

export default Layout;
