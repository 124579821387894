import MapboxDraw, {DrawCustomMode, DrawCustomModeThis, DrawFeature, MapMouseEvent} from '@mapbox/mapbox-gl-draw';
import {Feature, FeatureCollection, Geometry, LineString, Point, Position as GeojsonPosition} from 'geojson';
import bboxPolygon from '@turf/bbox-polygon';
import booleanIntersects from '@turf/boolean-intersects';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import distance from '@turf/distance';

// https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md

export type Position = {
  longitude: number,
  latitude: number
}

export const fromMapboxToGeometry = (point: MapboxDraw.DrawPoint): Point => ({
  type: 'Point',
  coordinates: point.getCoordinate()
});

export const toGeometry = (position: Position): Point => position && ({
  type: 'Point',
  coordinates: [position.longitude, position.latitude]
});

export type TmbDrawModeOptions<G extends Geometry> = {
  // the data to snap to
  snappingData: FeatureCollection<G>
  // max bounding box width to enable snapping; we want to disable snapping for further zooms to avoid
  // calculations on too many features; in degrees
  maxBboxWidth: number
  // minimum distance away from a linestring endpoint to snap to a different point; any coordinate within
  // this distance of any endpoint will be snapped to the nearest endpoint; in degrees 
  minDistanceToEndpoint: number
  // maximum distance snapping will work; if the distance between a coordinate and the snapping data is 
  // greater, there will be no snapping; in degrees
  maxDistanceToSnap: number
}

export type TmbDrawModeState<G extends Geometry, TOptions extends TmbDrawModeOptions<G>> = {
  options: TOptions
  // The data to snap to
  filteredSnappingData: Feature<G>[]
  // keep a reference to remove from map on stop
  onMoveCallback?: () => void
  // The object representing the snapped point on move (and associated info)
  snappedTramData?: SnappedTramData
}

export type SnappedTramData = {
  cuttingTramId: number | undefined
  distanceToTram: number
  position: Position
}

/**
 * Snaps the given coordinate to existing trams.
 * @param trams The trams to snap to.
 * @param options Options with configuration for distances (max distance for snapping, max distance
 * @param longitude Longitude of the coordinate to snap to the trams.
 * @param latitude Latitude of the coordinate to snap to the trams.
 */
export const snapToTram = (
  trams: Feature<LineString>[],
  options: TmbDrawModeOptions<LineString>,
  longitude: number,
  latitude: number,
): SnappedTramData | undefined => {
  if (trams.length === 0) {
    return;
  }

  // nearest point, distance and feature from (longitude, latitude)
  let nearestCoord: GeojsonPosition | undefined;
  let nearestDistance = options.maxDistanceToSnap;
  let nearestTram: Feature<LineString> | undefined;

  // find the closest tram (if any)
  trams.forEach(tram => {
    const nearestPoint = nearestPointOnLine(tram, [longitude, latitude]);
    if (nearestPoint.properties.dist < nearestDistance) {
      nearestCoord = nearestPoint.geometry.coordinates;
      nearestDistance = nearestPoint.properties.dist;
      nearestTram = tram;
    }
  });

  if (!nearestTram || !nearestCoord) {
    // could not snap to any features
    return;
  }

  // find distances from the closest point to first/last coordinates in tram
  const firstTramCoord = nearestTram.geometry.coordinates[0];
  const lastTramCoord = nearestTram.geometry.coordinates[nearestTram.geometry.coordinates.length - 1];
  const distanceNearestToFirstCoord = distance(nearestCoord, firstTramCoord);
  const distanceNearestToLastCoord = distance(nearestCoord, lastTramCoord);

  // find the closest endpoint to the nearest point in line;
  // so if the endpoint is near enough, snap to that endpoint instead of the nearest point in line
  const nearestEndpointCoord = distanceNearestToFirstCoord < distanceNearestToLastCoord
    ? firstTramCoord
    : lastTramCoord;
  const snapToEndpoint = Math.min(
    distanceNearestToFirstCoord,
    distanceNearestToLastCoord
  ) < options.minDistanceToEndpoint;

  if (!nearestTram.id) {
    throw new Error('Cannot find id for feature');
  }

  const snappedCoord = snapToEndpoint ? nearestEndpointCoord : nearestCoord;
  return {
    cuttingTramId: snapToEndpoint ? undefined: Number(nearestTram.id),
    distanceToTram: nearestDistance,
    position: {
      longitude: snappedCoord[0],
      latitude: snappedCoord[1]
    },
  };
};

/**
 * Creates a new draw mode. It includes some boilerplate for adding/removing callbacks, filtering features based on
 * bbox, etc.
 * @param drawCustomMode The draw mode to inherit from. Probably from MapboxDraw.modes.
 * @param initializeState Function to initialize the state when the mode is set up again when options change.
 * @param onClick Function to handle map click events.
 * @param onMouseMove Function to handle map mouse move events.
 */
export const buildMode = <
  G extends Geometry,
  TOptions extends TmbDrawModeOptions<G>,
  TState extends TmbDrawModeState<G, TOptions>
>
  ({
    drawCustomMode,
    initializeState,
    onClick,
    onMouseMove
  }: {
  drawCustomMode: DrawCustomMode
  initializeState: (drawCustomMode: DrawCustomModeThis, options: TOptions, filteredFeatures: Feature<G>[]) => TState
  onClick: (drawCustomMode: DrawCustomModeThis, state: TState, event: MapMouseEvent) => void
  onMouseMove: (drawCustomMode: DrawCustomModeThis, state: TState, event: MapMouseEvent) => void
}) => {
  const TmbCustomDrawMode = {...drawCustomMode};

  TmbCustomDrawMode.onSetup = function (this: DrawCustomModeThis, options: TOptions) {
    this.clearSelectedFeatures();

    const getFilteredFeatures = () => {
      const bbox = this.map.getBounds().toArray();
      if (bbox[1][0] - bbox[0][0] > options.maxBboxWidth) {
        return [];
      }
      const bboxPolygonFeature = bboxPolygon([bbox[0][0], bbox[0][1], bbox[1][0], bbox[1][1]]);
      return options.snappingData.features.filter(feature => booleanIntersects(bboxPolygonFeature, feature));
    };

    const state = initializeState(this, options, getFilteredFeatures());
    const onMove = () => {
      state.filteredSnappingData = getFilteredFeatures();
    };

    // for removing listener later on close
    state.onMoveCallback = onMove;
    this.map.on('moveend', onMove);

    return state;
  };

  TmbCustomDrawMode.onClick = function (state: TState, event: MapMouseEvent) {
    const bbox = this.map.getBounds().toArray();
    if (bbox[1][0] - bbox[0][0] <= state.options.maxBboxWidth) {
      onClick(this, state, event);
    }
  };
  TmbCustomDrawMode.onMouseMove = function (state: TState, event: MapMouseEvent) {
    const bbox = this.map.getBounds().toArray();
    if (bbox[1][0] - bbox[0][0] <= state.options.maxBboxWidth) {
      onMouseMove(this, state, event);
    }
  };
  TmbCustomDrawMode.onStop = function (this: DrawCustomModeThis, state: TState) {
    if (state.onMoveCallback) this.map.off('moveend', state.onMoveCallback);
  };
  TmbCustomDrawMode.toDisplayFeatures = function (_state, geojson, display) {
    display(geojson);
  };
  return TmbCustomDrawMode;
};


/**
 * Resets a feature in the given draw mode. It will set the coordinates as an empty array if it exists;
 * it will create and add a new feature (without properties) if it doesn't.
 * @param mode The draw mode containing the feature.
 * @param id Feature ID. For searching on onSetup calls.
 * @param type Geometry type.
 */
export const resetFeature = <T extends DrawFeature>(mode: DrawCustomModeThis, id: string, type: string): T => {
  let feature = mode.getFeature(id);
  if (!feature) {
    feature = mode.newFeature({
      id,
      type: MapboxDraw.constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: type,
        coordinates: [],
      } as Geometry
    });
    mode.addFeature(feature);
  }

  feature.setCoordinates([]);
  return feature as T;
};