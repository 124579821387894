import {Parada} from '@/domain/entities/Parada';
import Http from '@/domain/services/Http';
import {ParadaDto} from '@/repository/parada/ParadaDto';
import {toMoveParadaRequestDto, toNewParadaRequestDto} from './opMappers';
import {fromDto} from '../parada/paradaMappers';
import {MoveParadaRequestDto, NewParadaRequestDto} from '@/repository/op/OperationsDto';
import {MoveParadaRequest, NewParadaRequest} from '@/domain/entities/Operations';


export const PATH = 'op';

export interface OpRepository {
  newParada: (request: NewParadaRequest) => Promise<Parada>;
  moveParada: (request: MoveParadaRequest) => Promise<Parada>;
}

export const OpRepository = (apiBaseUrl: URL | string, http: Http): OpRepository => ({
  newParada: (request) =>
    http<ParadaDto, NewParadaRequestDto>(new URL(`${PATH}/new-parada`, apiBaseUrl), {
      method: 'POST',
      body: toNewParadaRequestDto(request)
    })
      .then(parada => fromDto(parada)),
  moveParada: (request) => http<ParadaDto, MoveParadaRequestDto>(
    new URL(`${PATH}/move-parada`, apiBaseUrl), {
      method: 'POST',
      body: toMoveParadaRequestDto(request)
    })
    .then(parada => fromDto(parada)),
});
