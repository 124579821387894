import React, {FC} from 'react';

import InputField from './InputField';

export type TextInputProps = {
  label: string,
  value?: string,
  required: boolean,
  readOnly: boolean,
  onChange: (value: string) => void
};

const TextInput: FC<TextInputProps> = ({label, value, required, readOnly, onChange}) =>
  <InputField
    label={label}
    value={value || ''}
    onChange={value => onChange(value.toString())}
    required={required}
    readOnly={readOnly}
    type="text"
  />;

export default TextInput;