import {LineString} from 'geojson';
import {Gir} from '@/domain/entities/Gir';

export type Tram = {
  id: number
  typeId: number
  geometry: LineString
  startDate: Date
  endDate?: Date
  girIds: Array<number>
}

export type TramCreation = Omit<Tram, 'id'>

/**
 * Entity defining a tram with new girs (not yet existing) associated.
 */
export type TramCreationWithNewGirs = Omit<TramCreation, 'girIds'> & {
  // either firstTramId or secondTramId
  newGirs: Array<Partial<Gir>>
}

export const fromPartial = (partialTram: Partial<Tram>): Tram | undefined => {
  if (!partialTram.id || !partialTram.typeId || !partialTram.geometry
    || !partialTram.startDate || !partialTram.girIds) {
    return;
  }

  return {
    id: partialTram.id,
    typeId: partialTram.typeId,
    geometry: partialTram.geometry,
    startDate: partialTram.startDate,
    girIds: partialTram.girIds,
    ...partialTram
  };
};

export const asTramCreationWithNewGirs = (
  partialTram: Partial<Tram>, newGirs: Array<Partial<Gir>>
): TramCreationWithNewGirs | undefined => {
  console.log(partialTram);
  if (!partialTram.typeId || !partialTram.geometry || !partialTram.startDate) {
    return;
  }

  return {
    typeId: partialTram.typeId,
    geometry: partialTram.geometry,
    startDate: partialTram.startDate,
    newGirs,
    ...partialTram
  };
};
