import {FeatureCollection, LineString, Point} from 'geojson';
import {AllEntities, AllFeatures} from '@/domain/entities/AllEntities';
import mapboxgl from 'mapbox-gl';

export const LAYER_NAME_PARADES = 'parades';
export const LAYER_NAME_ACCESSOS = 'accessos';
export const LAYER_NAME_TRAMS = 'trams';
export const LAYER_NAME_TRAMS_VERTEX = 'trams-vertex';


export const asFeatures = (entities?: AllEntities): AllFeatures | undefined => {
  if (entities === undefined) {
    return undefined;
  }

  const parades: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: entities.parades.map(({position, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: position
    }))
  };
  const accessos: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: entities.accessos.map(({position, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: position
    }))
  };
  const trams: FeatureCollection<LineString> = {
    type: 'FeatureCollection',
    features: entities.trams.map(({geometry, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry
    }))
  };

  return {parades, accessos, trams};
};

export const getSources = (isDrawEnabled: boolean, features?: AllFeatures): mapboxgl.Sources => {
  if (!features) {
    return {};
  }

  return {
    [LAYER_NAME_PARADES]: {
      type: 'geojson',
      data: features.parades
    },
    [LAYER_NAME_ACCESSOS]: {
      type: 'geojson',
      data: features.accessos
    },
    [LAYER_NAME_TRAMS]: {
      type: 'geojson',
      data: features.trams
    },
    [LAYER_NAME_TRAMS_VERTEX]: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: isDrawEnabled ? features.trams.features.map(feature => ({
          ...feature,
          geometry: {
            type: 'MultiPoint',
            coordinates: [
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[feature.geometry.coordinates.length - 1]
            ]
          }
        })) : []
      }
    },
  };
};

export const getLayers = (
  features: AllFeatures | undefined, selectedParadaId: number | undefined, selectedTramIds: number[] | undefined
): mapboxgl.AnyLayer[] => {
  if (!features) {
    return [];
  }

  // TODO TMB-403 symbolization
  return [{
    id: LAYER_NAME_ACCESSOS,
    type: 'circle',
    source: LAYER_NAME_ACCESSOS,
    paint: {
      'circle-color': '#4BF082',
      'circle-radius': 8
    }
  }, {
    id: LAYER_NAME_TRAMS,
    type: 'line',
    source: LAYER_NAME_TRAMS,
    paint: {
      'line-color': '#4B0082',
      'line-width': 2
    },
    layout: {
      'line-cap': 'round'
    }
  }, {
    id: LAYER_NAME_TRAMS_VERTEX,
    type: 'circle',
    source: LAYER_NAME_TRAMS_VERTEX,
    minzoom: 17,
    paint: {
      'circle-color': '#c79fe4',
      'circle-radius': 4,
      'circle-stroke-color': '#7b06d8',
      'circle-stroke-width': 3,
    }
  }, {
    id: `${LAYER_NAME_TRAMS}-selected`,
    type: 'line',
    source: 'trams',
    filter: selectedTramIds !== undefined
      ? ['in', ['id'], ['literal', selectedTramIds]]
      : ['==', ['boolean', true], false],
    paint: {
      'line-color': '#4B0082',
      'line-width': 2
    },
    layout: {
      'line-cap': 'round'
    }
  }, {
    id: LAYER_NAME_PARADES,
    type: 'circle',
    source: LAYER_NAME_PARADES,
    paint: {
      'circle-color': '#FB5082',
      'circle-radius': selectedParadaId !== undefined ? 3 : 6
    }
  }, {
    id: `${LAYER_NAME_PARADES}-selected-outer`,
    type: 'circle',
    source: LAYER_NAME_PARADES,
    filter: selectedParadaId !== undefined ? ['==', ['id'], selectedParadaId] : ['==', ['boolean', true], false],
    paint: {
      'circle-color': '#FB5082',
      'circle-radius': 9
    },
    layout: {
      visibility: selectedParadaId !== undefined ? 'visible' : 'none'
    }
  }, {
    id: `${LAYER_NAME_PARADES}-selected-inner`,
    type: 'circle',
    source: LAYER_NAME_PARADES,
    filter: selectedParadaId !== undefined ? ['==', ['id'], selectedParadaId] : ['==', ['boolean', true], false],
    paint: {
      'circle-color': '#fbed50',
      'circle-radius': selectedParadaId !== undefined ? 6 : 0
    }
  }];
};