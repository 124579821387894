import Http from '@/domain/services/Http';
import {TramType} from '@/domain/entities/TramType';
import {TramTypeDto} from '@/repository/tramType/TramTypeDto';
import {fromDto} from '@/repository/tramType/tramTypeMappers';


export const PATH = 'tram-types';

export interface TramTypeRepository {
  getAll: () => Promise<Array<TramType>>
}

export const TramTypeRepository = (apiBaseUrl: URL | string, http: Http): TramTypeRepository => ({
  getAll: () => http<Array<TramTypeDto>>(new URL(PATH, apiBaseUrl))
    .then(tramTypes => tramTypes.map(fromDto))
});
