import Http from '@/domain/services/Http';
import {Repository} from '@/domain/services/Repository';
import {Acces, AccesCreation} from '@/domain/entities/Acces';
import {fromDto, toDto} from './accesMappers';
import {AccesDto, AccesDtoCreation} from '@/repository/acces/AccesDto';


export const PATH = 'accessos';

export const AccesRepository = (apiBaseUrl: URL | string, http: Http): Repository<Acces, AccesCreation> => ({
  list: (date) =>
    http<Array<AccesDto>>(new URL(`${PATH}?date=${date.toISOString()}`, apiBaseUrl))
      .then(trams => trams.map(fromDto)),
  create: (tram: AccesCreation) =>
    http<AccesDto, AccesDtoCreation>(new URL(PATH, apiBaseUrl), {
      method: 'POST',
      body: toDto(tram)
    })
      .then(fromDto),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  put: (accesId: number, acces: Acces) => {
    throw new Error('Not implemented');
  }
});
