import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import {SxProps} from '@mui/material';

//UTILS
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import {Dayjs} from 'dayjs';

export type DatePickerProps = {
  label: string,
  readOnly: boolean,
  required: boolean,
  value?: Dayjs,
  onChange: (value?: Dayjs) => void,
  sx?: SxProps
};

const CalendarPicker: FC<DatePickerProps> = ({label, required, readOnly, value, onChange, sx}) => {
  
  const calendarSx: SxProps = {
    '& .MuiInputBase-root': {
      bgcolor: 'white'
    },
    ...sx
  };
  
  return <Box>
    <FormControl fullWidth required={required}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          disabled={readOnly}
          label={label}
          slotProps={{
            textField: {size: 'small'},
            field: {clearable: true}
          }}
          sx={calendarSx}
          onChange={(day) => onChange(day == null ? undefined : day)}
        />
      </LocalizationProvider>
    </FormControl>
  </Box>;
};

export default CalendarPicker;
