import React, {FC} from 'react';
import NumericInput from './forms/NumericInput';
import {useTranslation} from 'react-i18next';
import TextInput from './forms/TextInput';
import SelectInput from './forms/SelectInput';
import CalendarPicker from './forms/CalendarPicker';
import dayjs, {Dayjs} from 'dayjs';
import Box from '@mui/material/Box';
import {Button, Divider} from '@mui/material';
import {fromPartial, Parada, ParadaCreation} from '@/domain/entities/Parada';
import {ParadaType} from '@/domain/entities/ParadaType';
import {Gir} from '@/domain/entities/Gir';
import GirSelector from '@/components/forms/GirSelector';
import SaveIcon from '@mui/icons-material/Save';

export type ParadaCardProps = {
  parada: Partial<Parada>,
  girs?: Array<Gir>,
  availableTypes: ParadaType[],
  readOnlyProps: boolean, // all properties other than dates
  readOnlyDates: boolean,
  titleKey: string,
  onChange?: (parada: Partial<Parada>) => void
  onSave?: (parada: Parada | ParadaCreation) => void
};

const DEFAULT_CITY_CODE = 0;
const DEFAULT_CODE = 0;

const getTypeOptions = (availableTypes: ParadaType[]) => availableTypes.map(availableType => ({
  id: availableType.id + '',
  label: availableType.name
}));

const getSelectedTypeId = (parada: Partial<Parada>, availableTypes: ParadaType[]): string => {
  if ('typeId' in parada) {
    const type = availableTypes.find(type => type.id === parada.typeId);
    return type ? `${type.id}` : '';
  } else {
    return '';
  }
};

const toDayjs = (date?: Date) => date && dayjs(date);

const ParadaDetail: FC<ParadaCardProps> = ({
  parada,
  girs,
  availableTypes,
  readOnlyProps,
  readOnlyDates,
  onChange,
  onSave
}) => {
  const {t} = useTranslation();

  const changeName = (value: string) => onChange?.({...parada, name: value});
  const changeType = (value: string) => onChange?.({...parada, typeId: Number(value)});
  const changeCityCode = (value: number) => onChange?.({...parada, cityCode: value});
  const changeCode = (value: number) => onChange?.({...parada, code: value});
  const changeStartDate = (value?: Dayjs) => onChange?.({...parada, startDate: value?.toDate()});
  const changeEndDate = (value?: Dayjs) => onChange?.({...parada, endDate: value?.toDate()});
  const changeGirIds = (value?: Array<number>) => onChange?.({...parada, girIds: value});

  const entity = fromPartial(parada);
  return <Box mt={2}>
    <TextInput
      label={t('parada_name')}
      value={parada.name}
      required={true}
      readOnly={readOnlyProps}
      onChange={changeName}/>
    <SelectInput
      label={t('parada_type')}
      required={true}
      readOnly={readOnlyProps}
      value={getSelectedTypeId(parada, availableTypes)}
      options={getTypeOptions(availableTypes)}
      onChange={changeType}
    />
    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
      <NumericInput
        label={t('parada_city_code')}
        value={parada.cityCode || DEFAULT_CITY_CODE}
        required={true}
        readOnly={readOnlyProps}
        onChange={changeCityCode}/>
      <NumericInput
        label={t('parada_code')}
        value={parada.code || DEFAULT_CODE}
        required={true}
        readOnly={readOnlyProps}
        onChange={changeCode}/>
    </Box>
    <Box mb={2.5} sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
      <CalendarPicker
        label={t('start_date')}
        readOnly={readOnlyDates}
        required={true}
        value={toDayjs(parada.startDate)}
        onChange={changeStartDate}
        sx={{width: '100%'}}
      />
      <CalendarPicker
        label={t('end_date')}
        readOnly={readOnlyDates}
        required={false}
        value={toDayjs(parada.endDate)}
        onChange={changeEndDate}
        sx={{width: '100%'}}
      />
    </Box>
    {
      girs ? <Divider flexItem variant='fullWidth' sx={{my: 2}}/> : <></>
    }
    {
      girs ? <GirSelector girs={girs} selectedGirIds={parada.girIds} onChange={changeGirIds} multiple={true} /> : <></>
    }
    <Button
      sx={{mt: 2}}
      startIcon={<SaveIcon />}
      variant='outlined'
      disabled={!entity}
      onClick={() => entity && onSave?.(entity)}>
      {t('save')}
    </Button>
  </Box>;
};

export default ParadaDetail;
