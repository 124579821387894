import React, {FC} from 'react';

import InputField from './InputField';

export type NumericInputProps = {
  label: string,
  value: number,
  required: boolean,
  readOnly: boolean,
  onChange: (value: number) => void
};

const NumericInput: FC<NumericInputProps> = ({label, value, required, readOnly, onChange}) =>
  <InputField
    label={label}
    value={value}
    onChange={value => onChange(Number(value))}
    required={required}
    readOnly={readOnly}
    type='number'
  />;

export default NumericInput;