import React, {FC} from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {SvgIconProps} from '@mui/material/SvgIcon/SvgIcon';

const CreateTramIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width={48} height={48} viewBox="0 0 24 24" fill="none" sx={{fontSize: '32px'}}>
    <path d="M19.8 13.8V5.6c0-2.2-1.8-4-4-4s-4 1.8-4 4v10c0 1.1-.9 2-2 2s-2-.9-2-2V7.4c1.2-.4 2-1.5 2-2.8 0-1.7-1.3-3-3-3s-3 1.3-3 3c0 1.3.8 2.4 2 2.8v8.2c0 2.2 1.8 4 4 4s4-1.8 4-4v-10c0-1.1.9-2 2-2s2 .9 2 2v8.2c-1.2.4-2 1.5-2 2.8 0 1.7 1.3 3 3 3s3-1.3 3-3c0-1.3-.8-2.4-2-2.8z"/>
    <circle
      cx={18.9}
      cy={17.5}
      r={5.8}
      style={{
        fill: '#fff',
      }}
    />
    <path d="M18.7 22.6c-2.7.1-5-2.3-5-5s2.4-5.1 5.2-5c2.6.1 5.1 2.3 5 5.2-.1 2.6-2.5 4.8-5.2 4.8zm-.7-4.3v2c0 .3.2.5.5.5h.5c.4 0 .5-.1.5-.5v-2h2.2c.3 0 .5-.2.5-.4v-.6c0-.4-.2-.5-.5-.5h-2.1v-2c0-.3-.2-.5-.4-.5h-.7c-.3 0-.4.2-.4.4v2.1H16c-.3 0-.5.2-.5.5v.6c0 .3.2.5.5.5h1.4c.1-.1.3-.1.6-.1z"/>
  </SvgIcon>
);

export default CreateTramIcon;