import {FeatureCollection, LineString, Point} from 'geojson';
import {AllEntities, AllFeatures} from '@/domain/entities/AllEntities';
import mapboxgl from 'mapbox-gl';

export const LAYER_NAME_PARADES = 'parades';
export const LAYER_NAME_ACCESSOS = 'accessos';
export const LAYER_NAME_TRAMS = 'trams';
export const LAYER_NAME_TRAMS_VERTEX = 'trams-vertex';


export const asFeatures = (entities?: AllEntities): AllFeatures | undefined => {
  if (entities === undefined) {
    return undefined;
  }

  const parades: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: entities.parades.map(({position, id, code}) => ({
      type: 'Feature',
      id,
      properties: {
        code
      },
      geometry: position
    }))
  };
  const accessos: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: entities.accessos.map(({position, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: position
    }))
  };
  const trams: FeatureCollection<LineString> = {
    type: 'FeatureCollection',
    features: entities.trams.map(({geometry, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry
    }))
  };

  return {parades, accessos, trams};
};

export const getSprite = () => `${location.protocol}//${location.host}/sprites/shape`;

export const getSources = (
  isDrawParadaEnabled: boolean, isDrawTramEnabled: boolean, features?: AllFeatures
): mapboxgl.Sources => {
  if (!features) {
    return {};
  }

  return {
    [LAYER_NAME_PARADES]: {
      type: 'geojson',
      data: features.parades
    },
    [LAYER_NAME_ACCESSOS]: {
      type: 'geojson',
      data: features.accessos
    },
    [LAYER_NAME_TRAMS]: {
      type: 'geojson',
      data: features.trams
    },
    [LAYER_NAME_TRAMS_VERTEX]: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: isDrawParadaEnabled ? features.trams.features.map(feature => ({
          ...feature,
          geometry: {
            type: 'MultiPoint',
            coordinates: [
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[feature.geometry.coordinates.length - 1]
            ]
          }
        })) : []
      }
    },
  };
};

export const getLayers = (
  features: AllFeatures | undefined, selectedParadaId: number | undefined, selectedTramIds: number[] | undefined
): mapboxgl.AnyLayer[] => {
  if (!features) {
    return [];
  }
  return [
    {
      id: `${LAYER_NAME_TRAMS}-outer`,
      type: 'line',
      source: LAYER_NAME_TRAMS,
      paint: {
        'line-color': '#ffffff',
        'line-width': ['interpolate', ['linear'], ['zoom'], 14, 3, 20, 8],
        'line-opacity': 0.85
      },
      minzoom: 14,
      layout: {
        'line-cap': 'round'
      }
    },
    {
      id: LAYER_NAME_TRAMS,
      type: 'line',
      source: LAYER_NAME_TRAMS,
      paint: {
        'line-color': '#0fafa9',
        'line-width': ['interpolate', ['linear'], ['zoom'], 14, 1.5, 20, 4],
        'line-opacity': 0.85
      },
      minzoom: 14,
      layout: {
        'line-cap': 'round'
      }
    },
    {
      id: `${LAYER_NAME_TRAMS}-selected-arrow`,
      type: 'symbol',
      source: 'trams',
      filter: selectedTramIds !== undefined
        ? ['in', ['id'], ['literal', selectedTramIds]]
        : ['==', ['boolean', true], false],
      minzoom: 14,
      paint: {
        'icon-color': '#E30620',
        'icon-halo-width': ['interpolate', ['linear'], ['zoom'], 14, 1, 20, 2],
        'icon-halo-color': '#ffffff'
      },
      layout: {
        'symbol-placement': 'line-center',
        'icon-image': 'arrow',
        'icon-size': 1.8
      }
    },
    {
      id: `${LAYER_NAME_TRAMS}-selected`,
      type: 'line',
      source: 'trams',
      filter: selectedTramIds !== undefined
        ? ['in', ['id'], ['literal', selectedTramIds]]
        : ['==', ['boolean', true], false],
      paint: {
        'line-color': '#E30620',
        'line-width': ['interpolate', ['linear'], ['zoom'], 14, 2, 20, 4]
      },
      minzoom: 14
    },
    {
      id: LAYER_NAME_TRAMS_VERTEX,
      type: 'circle',
      source: LAYER_NAME_TRAMS_VERTEX,
      minzoom: 17,
      paint: {
        'circle-color': '#0fafa9',
        'circle-radius': 4,
        'circle-stroke-color': '#ffffff',
        'circle-stroke-width': 1,
        'circle-opacity': 0.5
      },
    }, 
    {
      id: LAYER_NAME_PARADES,
      type: 'circle',
      source: LAYER_NAME_PARADES,
      paint: {
        'circle-color': '#E30620',
        'circle-radius': selectedParadaId !== undefined ? 3 : 6,
        'circle-stroke-color': '#ffffff',
        'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 14, 1, 20, 2]
      },
      minzoom: 14.5
    }, 
    {
      id: LAYER_NAME_ACCESSOS,
      type: 'circle',
      source: LAYER_NAME_ACCESSOS,
      paint: {
        'circle-color': '#71030f',
        'circle-radius': 8,
        'circle-stroke-color': '#ffffff',
        'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 14, 1, 20, 2]
      },
      minzoom: 14.5,
    },
    {
      id: `${LAYER_NAME_PARADES}-selected-outer`,
      type: 'circle',
      source: LAYER_NAME_PARADES,
      filter: selectedParadaId !== undefined ? ['==', ['id'], selectedParadaId] : ['==', ['boolean', true], false],
      paint: {
        'circle-color': '#E30620',
        'circle-radius': 9,
        'circle-opacity': 0.85
      },
      minzoom: 14,
      layout: {
        visibility: selectedParadaId !== undefined ? 'visible' : 'none'
      }
    }, 
    {
      id: `${LAYER_NAME_PARADES}-selected-inner`,
      type: 'circle',
      source: LAYER_NAME_PARADES,
      filter: selectedParadaId !== undefined ? ['==', ['id'], selectedParadaId] : ['==', ['boolean', true], false],
      paint: {
        'circle-color': '#ffffff',
        'circle-radius': selectedParadaId !== undefined ? 6 : 0
      },
      minzoom: 14
    },
    {
      id: `${LAYER_NAME_PARADES}-name`,
      type: 'symbol',
      source: LAYER_NAME_PARADES,
      layout: {
        'text-field': ['get', 'code'],
        'text-size': 14,
        'text-anchor': 'left',
        'text-offset': [1, 0]
      },
      paint: {
        'text-color': '#000000',
        'text-halo-color': '#ffffff',
        'text-halo-width': 2
      },
      minzoom: 16
    }
  ];
};
