import {MoveParadaRequestDto, NewParadaRequestDto, NewTramRequestDto} from '@/repository/op/OperationsDto';
import {MoveParadaRequest, NewParadaRequest, NewTramRequest} from '@/domain/entities/Operations';

export const toNewParadaRequestDto = (domain: NewParadaRequest): NewParadaRequestDto => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString(),
  };
};

export const toMoveParadaRequestDto = (domain: MoveParadaRequest): MoveParadaRequestDto => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString(),
  };
};

export const toNewTramRequestDto = (domain: NewTramRequest): NewTramRequestDto => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString(),
  };
};
