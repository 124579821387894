import React, {FC} from 'react';

import {useTranslation} from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Parada} from '@/domain/entities/Parada';

//TYPES
export type LogoutMenuProps = {
  parades: Array<Parada>,
  onParadeSearched: (parada: Parada) => void
};

type ParadeOption = {
  label: string,
  id: number
}

const SearchParadeMenu: FC<LogoutMenuProps> = ({parades, onParadeSearched}) => {

  const {t} = useTranslation();

  if(!parades){
    return <></>;
  }

  const handleChange = (p: ParadeOption) => {
    const selectedParade = parades.filter(parade => parade.id === p.id)[0];
    onParadeSearched(selectedParade);
  };

  const paradesOptions: Array<ParadeOption> = parades
    .sort((a, b) => a.code - b.code)
    .map(parade => ({
      label: `${parade.code} - ${parade.name}`,
      id: parade.id
    }));
  
  return <Autocomplete
    autoHighlight={true}
    size='small'
    disableClearable
    sx={{width: '350px', '& fieldset': { border: 'none' }}}
    options={paradesOptions}
    renderInput={(params) => <TextField {...params} autoFocus={true} placeholder={t('paradaCode')}
      InputProps={{
        ...params.InputProps,
        endAdornment: undefined,
      }}/>}
    onChange={(_, parade) => handleChange(parade)}
  />;
};
export default SearchParadeMenu;