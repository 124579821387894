import {Tram, TramCreation} from '@/domain/entities/Tram';
import {TramDto, TramDtoCreation} from '@/repository/tram/TramDto';

export const toDto = (domain: Tram | TramCreation): TramDto | TramDtoCreation => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString(),
  };
};

export const fromDto = (dto: TramDto): Tram => {
  return {
    ...dto,
    startDate: new Date(dto.startDate),
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
  };
};
