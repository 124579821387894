import React, {FC, useState} from 'react';

import {MINISIDEPANEL_CONFIG, Mode} from '@/config';

import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import styled from '@mui/system/styled';
import ToggleButtonGroup, {toggleButtonGroupClasses} from '@mui/material/ToggleButtonGroup';
import Popper from '@mui/material/Popper';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';

import LogoutMenu from '@/components/LogoutMenu';
import SearchParadeMenu from '@/components/SearchParadeMenu';

import {Parada} from '@/domain/entities/Parada';

//TYPES
export type ModePickerProps = {
  parades: Array<Parada> | undefined,
  sidePanelContent: React.ReactElement,
  miniSidePanelSelectedActionId: Mode,
  onModeChanged: (id: Mode ) => void,
  onLogout: () => void,
  onParadeSearched: (parada: Parada) => void
};

//STYLES

const sx = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: '1px solid lightgrey',
    borderRadius: '8px',
    opacity: 0.95,
    position: 'absolute',
    top: 'calc(28px + 50%)',
    transform: 'translateY(-50%)',
    left: 8
  },
  iconButton: {
    my: 1
  },
  logoutMenu: {
    '& .MuiPaper-root': {
      border: '1px solid lightgrey'
    },
    '& .MuiList-root' : {
      m: 0,
      p: 0
    },
    paddingBottom: 1,
    ml: 6,
    mt: -4,
    opacity: 0.95,
    borderRadius: '8px'
  },
  searchingMenu: {
    top: '-40px !important',
    left: '72px !important',
    paddingBottom: 1,
    ml: 0,
    mt: 0,
    opacity: 0.95,
    borderRadius: '8px'
  }
};

const ModePicker: FC<ModePickerProps> = ({parades, sidePanelContent, miniSidePanelSelectedActionId, onModeChanged, onLogout, onParadeSearched}) => {

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      border: 0,
      borderRadius: theme.shape.borderRadius,
      [`&.${toggleButtonGroupClasses.disabled}`]: {
        border: 0,
      }
    }
  }));

  const handleActionClick = (id: string) => {
    if (Object.values(Mode).includes(id as Mode)) {
      const mode: Mode = id as Mode;
      if (miniSidePanelSelectedActionId === mode && sidePanelContent) {
        onModeChanged(Mode.VIEW);
      } else {
        onModeChanged(mode);
      }
    }
  };

  const [anchorElLogoutMenu, setAnchorElLogoutMenu] = useState<null | HTMLElement>(null);
  const [anchorElSearching, setAnchorElSearching] = useState<null | HTMLElement>(null);
  const openLogout = Boolean(anchorElLogoutMenu);
  const openSearching = Boolean(anchorElSearching);

  const modeGroups = [...new Set(MINISIDEPANEL_CONFIG.map(item => item.group))];

  const handleOpenLogoutMenu = (event: React.MouseEvent<HTMLButtonElement>) => anchorElLogoutMenu ? setAnchorElLogoutMenu(null) : setAnchorElLogoutMenu(event.currentTarget);
  const handleOpenSearching = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSearching(anchorElSearching ? null : event.currentTarget);
  };
  const handleClose = () => setAnchorElLogoutMenu(null);
  const handleLogoutClick = () => {
    setAnchorElLogoutMenu(null);
    onLogout();
  };

  const handleParadeSearch = (parada: Parada) => {
    if(parada) {
      setAnchorElSearching(null);
      onParadeSearched(parada);
    }
  };

  return <Paper elevation={0} sx={sx.paper}>
    {
      <>
        <IconButton onClick={handleOpenSearching} sx={sx.iconButton}>
          <SearchIcon/>
        </IconButton>
        {
          openSearching && parades && <Popper open={true} anchorEl={anchorElSearching} sx={sx.searchingMenu}>
            <Paper>
              <SearchParadeMenu parades={parades} onParadeSearched={handleParadeSearch}/>
            </Paper>
          </Popper>
        }
      </>
    }
    <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1, display: openLogout ? 'none' : 'block'}} />

    {
      modeGroups.map((group, index) => <>
        <StyledToggleButtonGroup key={index} orientation='vertical' value={miniSidePanelSelectedActionId} exclusive size='small'
          onChange={(e, id) => handleActionClick(id)}>
          {
            MINISIDEPANEL_CONFIG
              .filter(action => action.group === group)
              .map(({id, content}) =>
                <ToggleButton value={id} key={id}>
                  {content}
                </ToggleButton>
              )
          }
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
      </>
      )
    }

    {
      <IconButton onClick={handleOpenLogoutMenu} sx={sx.iconButton}>
        <AccountCircleIcon/>
        {
          openLogout && <Menu open={true} onClose={handleClose} anchorEl={anchorElLogoutMenu} sx={sx.logoutMenu}>
            <LogoutMenu onLogout={handleLogoutClick}/>
          </Menu>
        }
      </IconButton>
    }

  </Paper>;
};

export default ModePicker;