import React, {FC} from 'react';
import {Gir} from '@/domain/entities/Gir';
import GirSelector from '@/components/forms/GirSelector';


export type ExistingGirSelectorProps = {
  girs: Array<Gir>,
  selectedGirIds?: Array<number>
  onChange: (girIds: Array<number>) => void,
  onGirHover: (ids: Array<number>) => void
};

/**
 * Gir selector where all girs exist.
 */
const ExistingGirSelector: FC<ExistingGirSelectorProps> = ({girs, selectedGirIds, onChange, onGirHover}) => {
  // row id and gir id are the same for simplicity
  const rows = girs.map(gir => ({
    id: gir.id,
    girId: gir.id,
    firstTramId: gir.firstTramId,
    secondTramId: gir.secondTramId,
  }));

  return <GirSelector rows={rows} selectedRowIds={selectedGirIds} onChange={onChange} onGirHover={onGirHover}/>;
};

export default ExistingGirSelector;