import MapboxDraw, {DrawCustomModeThis, DrawLineString, DrawPoint, MapMouseEvent} from '@mapbox/mapbox-gl-draw';
import {
  buildMode,
  fromMapboxToGeometry,
  Position,
  resetFeature,
  snapToTram,
  TmbDrawModeOptions,
  TmbDrawModeState,
  toGeometry
} from './snapping';
import {Feature, LineString, Point} from 'geojson';
import distance from '@turf/distance';
import {DrawTramOptions} from '@/components/snapping/DrawTramMode';


export type DrawParadaState = TmbDrawModeState<LineString, DrawParadaOptions> & {
  // The line for visualizing the parada, from the intersecting point to the actual location
  drawingLine: MapboxDraw.DrawLineString
  // The next point to be drawn, either the snapped/intersecting point or the actual location
  nextPoint: MapboxDraw.DrawPoint
  // The point of intersection with the tram(s)
  intersection?: Position,
  // id of tram if intersectionPoint cuts it in the middle
  cuttingTramId?: number
}

export type DrawParadaOptions = TmbDrawModeOptions<LineString> & {
  maxDistanceFromIntersectionInMeters: number
};

// triggered when both the first intersecting point and the final location are set
export const EVENT_PARADA_LOCATION = 'draw.parada.location';

export type ParadaLocationEvent = {
  // position for the new parada; might be undefined for the first click (placing intersection but not parada location)
  position?: Point
  // point of intersection with one or more trams
  intersection?: Point
  // id of tram if intersectionPoint cuts it in the middle; undefined if it intersects at the endpoints
  cuttingTramId?: number
}

const DRAW_LINE_FEATURE_ID = 'drawingLine';
const DRAW_POINT_FEATURE_ID = 'drawingPoint';

export default () => buildMode<LineString, DrawParadaOptions, DrawParadaState>({
  drawCustomMode: MapboxDraw.modes.draw_line_string,
  initializeState,
  onClick,
  onMouseMove
});

const initializeState = (
  customMode: DrawCustomModeThis, options: DrawParadaOptions, filteredFeatures: Feature<LineString>[]
): DrawParadaState => ({
  filteredSnappingData: filteredFeatures,
  options,
  ...initializeDrawingLine(customMode, options, filteredFeatures),
  nextPoint: resetFeature<DrawPoint>(customMode, DRAW_POINT_FEATURE_ID, 'Point'),
});

const initializeDrawingLine = (
  customMode: DrawCustomModeThis, options: DrawTramOptions, filteredFeatures: Feature<LineString>[]
) => {
  const drawingLine = customMode.getFeature(DRAW_LINE_FEATURE_ID) as DrawLineString;
  if (!drawingLine || !drawingLine.getCoordinates() || !drawingLine.getCoordinates().length) {
    // if there's no line with at least one coordinate, reset
    return {
      drawingLine: resetFeature<DrawLineString>(customMode, DRAW_LINE_FEATURE_ID, 'LineString')
    };
  }

  const longitude = drawingLine.getCoordinates()[0][0];
  const latitude = drawingLine.getCoordinates()[0][1];
  const snapData = snapToTram(filteredFeatures, options, longitude, latitude);
  const intersects = snapData?.distanceToTram && snapData?.distanceToTram < 5e-6;

  return {
    // return existing line if it intersects any trams; otherwise reset to empty
    drawingLine: intersects
      ? drawingLine
      : resetFeature<DrawLineString>(customMode, DRAW_LINE_FEATURE_ID, 'LineString'),
    // return intersecting point if it intersects any trams and the drawing line is not already done
    intersection: !intersects || drawingLine.properties?.['done'] ? undefined : snapData?.position,
    cuttingTramId: intersects ? snapData?.cuttingTramId : undefined
  };
};

const onClick = (drawCustomMode: DrawCustomModeThis, state: DrawParadaState) => {
  if (state.intersection) {
    // second point, parada is placed with both intersecting point and actual position
    const event: ParadaLocationEvent = {
      position: fromMapboxToGeometry(state.nextPoint),
      cuttingTramId: state.cuttingTramId,
      intersection: toGeometry(state.intersection),
    };
    state.drawingLine?.setProperty('done', true);
    drawCustomMode.map.fire(EVENT_PARADA_LOCATION, event);
    state.intersection = undefined;
    state.cuttingTramId = undefined;
  } else if (state.snappedTramData) {
    // first (intersecting) point
    state.intersection = state.snappedTramData.position;
    state.cuttingTramId = state.snappedTramData.cuttingTramId;
    state.drawingLine.setCoordinates([state.nextPoint.getCoordinate()]);
    state.drawingLine?.setProperty('done', false);
    const event: ParadaLocationEvent = {
      intersection: toGeometry(state.intersection),
      cuttingTramId: state.cuttingTramId,
    };
    drawCustomMode.map.fire(EVENT_PARADA_LOCATION, event);
  }
};
const onMouseMove = (drawCustomMode: DrawCustomModeThis, state: DrawParadaState, event: MapMouseEvent) => {
  let point;
  if (state.intersection) {
    point = calcPointWithMaxDistance(state.intersection, state.options.maxDistanceFromIntersectionInMeters, event);
    state.drawingLine.updateCoordinate('1', point.longitude, point.latitude);
  } else {
    const snappedTramData = snapToTram(state.filteredSnappingData, state.options, event.lngLat.lng, event.lngLat.lat);
    state.snappedTramData = snappedTramData;
    if (!snappedTramData) {
      state.drawingLine.setCoordinates([]);
      state.nextPoint.setCoordinates([]);
      return;
    }
    point = snappedTramData.position;
  }
  state.nextPoint.updateCoordinate(point.longitude, point.latitude);
};

const calcPointWithMaxDistance = (
  intersection: Position, maxDistanceInMeters: number, event: MapMouseEvent
): Position => {
  const from = [event.lngLat.lng, event.lngLat.lat];
  const to = [intersection.longitude, intersection.latitude];

  if (distance(from, to, {units: 'meters'}) < maxDistanceInMeters) {
    return {
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    };
  } else {
    // with trigonometry! yay!
    const EARTH_RADIUS = 6_371_000;
    const dx = event.lngLat.lng - intersection.longitude;
    const dy = event.lngLat.lat - intersection.latitude;
    const bearing = Math.atan2(dx, dy);
    const fromLatitudeInRadians = intersection.latitude * (Math.PI / 180);
    const fromLongitudeInRadians = intersection.longitude * (Math.PI / 180);

    // from https://www.movable-type.co.uk/scripts/latlong.html
    const toLatitudeInRadians = Math.asin(
      Math.sin(fromLatitudeInRadians) * Math.cos(maxDistanceInMeters / EARTH_RADIUS) +
      Math.cos(fromLatitudeInRadians) * Math.sin(maxDistanceInMeters / EARTH_RADIUS) * Math.cos(bearing));
    const toLongitudeInRadians = fromLongitudeInRadians + Math.atan2(
      Math.sin(bearing) * Math.sin(maxDistanceInMeters / EARTH_RADIUS) * Math.cos(fromLatitudeInRadians),
      Math.cos(maxDistanceInMeters / EARTH_RADIUS) - Math.sin(fromLatitudeInRadians) * Math.sin(toLatitudeInRadians));
    return {
      longitude: toLongitudeInRadians * (180 / Math.PI),
      latitude: toLatitudeInRadians * (180 / Math.PI)
    };
  }
};
