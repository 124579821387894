import {Gir, GirCreation} from '@/domain/entities/Gir';
import {GirDto, GirDtoCreation} from '@/repository/gir/GirDto';


export const toDto = (domain: Gir | GirCreation): GirDto | GirDtoCreation => ({
  ...domain
});

export const fromDto = (dto: GirDto): Gir => ({
  ...dto
});