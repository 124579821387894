import {inject} from '@/domain/dependencyInjection';
import {OpRepository} from '@/repository/op/OpRepository';
import {REPOSITORIES} from '@/domain/services/Repository';
import {Tram, TramCreation} from '@/domain/entities/Tram';
import {Gir} from '@/domain/entities/Gir';

type NewTram = Omit<TramCreation, 'girIds'>

export const newTram = async (
  tram: NewTram, newGirs: Array<Partial<Gir>>,
  startPointCutTramId: number | undefined, endPointCutTramId: number | undefined
): Promise<Tram> => {
  const repository = inject<OpRepository>(REPOSITORIES.op);

  const nextTramIds = newGirs
    .filter(newGir => newGir.firstTramId === undefined && newGir.secondTramId !== undefined)
    .map(newGir => newGir.secondTramId as number);
  const previousTramIds = newGirs
    .filter(newGir => newGir.firstTramId !== undefined && newGir.secondTramId === undefined)
    .map(newGir => newGir.firstTramId as number);

  return await repository.newTram({
    geometry: tram.geometry,
    typeId: tram.typeId,
    startPointCutTramId,
    endPointCutTramId,
    nextTramIds,
    previousTramIds,
    startDate: tram.startDate,
    endDate: tram.endDate
  });
};
