import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Map from '@geomatico/geocomponents/Map/Map';

import type {MapRef} from 'react-map-gl';

import {MapLayerMouseEvent} from 'mapbox-gl';
import {Viewport} from '@geomatico/geocomponents/types/common';
import DrawParadaControl from '@/components/DrawParadaControl';
import {ParadaLocationEvent} from '@/components/snapping/DrawParadaMode';
import {AllFeatures} from '@/domain/entities/AllEntities';
import {getLayers, getSources, getSprite} from '@/services/mapService';
import DrawTramControl from '@/components/DrawTramControl';
import {TramGeometryEvent} from '@/components/snapping/DrawTramMode';
import {Parada} from '@/domain/entities/Parada';

const MAX_DISTANCE_IN_METERS = 20;

export type MainContentProps = {
  paradaSearched: Parada | undefined,
  features?: AllFeatures,
  isDrawParadaEnabled: boolean,
  isDrawTramEnabled: boolean,
  viewport: Viewport,
  mapStyle: string,
  onViewportChange: (viewport: Viewport) => void
  onParadaPosition: (event: ParadaLocationEvent) => void
  onParadaSelected: (selectedParadaId?: number) => void
  selectedParadaId?: number,
  selectedTramIds?: Array<number>
  onTramGeometry: (event: TramGeometryEvent) => void
};

const MainContent: FC<MainContentProps> = ({
  paradaSearched,
  isDrawParadaEnabled,
  isDrawTramEnabled,
  features,
  mapStyle,
  viewport,
  onViewportChange,
  onParadaPosition,
  onParadaSelected,
  selectedParadaId,
  selectedTramIds,
  onTramGeometry
}) => {
  
  const mapRef = useRef<MapRef>(null);

  const [cursor, setCursor] = useState<string>('auto');
  const sources = useMemo(
    () => getSources(isDrawParadaEnabled, isDrawTramEnabled, features),
    [features, isDrawParadaEnabled, isDrawTramEnabled]);
  const layers = useMemo(
    () => getLayers(features, selectedParadaId, selectedTramIds),
    [features, selectedParadaId, selectedTramIds]);

  const sprite = useMemo(getSprite, []);

  const handleClick = (event: MapLayerMouseEvent) => {
    if (!isDrawParadaEnabled) {
      const id = event.features?.[0]?.id;
      onParadaSelected(id ? Number(id) : undefined);
    }
  };

  useEffect(() => {
    if(paradaSearched){
      mapRef?.current?.flyTo({
        center: [paradaSearched.position.coordinates[0], paradaSearched.position.coordinates[1]],
        zoom: 18,
        duration: 2000
      });
    }
  }, [paradaSearched]);
  
  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('grab'), []);

  return <>
    <Map
      ref={mapRef}
      mapStyle={mapStyle}
      viewport={viewport}
      onViewportChange={onViewportChange}
      sources={sources}
      layers={layers}
      cursor={cursor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      interactiveLayerIds={['parades']}
      onClick={handleClick}
      sprite={sprite}
    >
      {isDrawParadaEnabled && features &&
      <DrawParadaControl
        snappingData={features}
        maxDistanceInMeters={MAX_DISTANCE_IN_METERS}
        onLocation={onParadaPosition}/>
      }
      {isDrawTramEnabled && features &&
      <DrawTramControl snappingData={features} onGeometryDrawn={onTramGeometry}/>
      }
    </Map>
  </>;
};

export default MainContent;
