import {ParadaType} from '@/domain/entities/ParadaType';
import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES} from '@/domain/services/Repository';
import {ParadaTypeRepository} from '@/repository/paradaType/ParadaTypeRepository';


export const getParadaTypes = async (): Promise<ParadaType[]> => {
  const repository = inject<ParadaTypeRepository>(REPOSITORIES.paradaType);
  return repository.getAll();
};

export default getParadaTypes;