import React, {FC, useCallback, useMemo, useState} from 'react';
import Map from '@geomatico/geocomponents/Map/Map';

import {MAPSTYLES} from '@/config';
import BaseMapPicker from '@geomatico/geocomponents/Map/BaseMapPicker';
import {MapLayerMouseEvent} from 'mapbox-gl';
import {Viewport} from '@geomatico/geocomponents/types/common';
import DrawParadaControl from '@/components/DrawParadaControl';
import {ParadaLocationEvent} from '@/components/snapping/DrawParadaMode';
import {AllFeatures} from '@/domain/entities/AllEntities';
import DrawTramControl from '@/components/DrawTramControl';
import {getLayers, getSources} from '@/services/mapService';

const MAX_DISTANCE_IN_METERS = 20;


export type MainContentProps = {
  features?: AllFeatures,
  isDrawEnabled: boolean,
  viewport: Viewport,
  mapStyle: string,
  onMapStyleChange: (style: string) => void,
  onViewportChange: (viewport: Viewport) => void
  onParadaPosition: (event: ParadaLocationEvent) => void
  onParadaSelected: (selectedParadaId?: number) => void
  selectedParadaId?: number,
  selectedTramIds?: Array<number>
};

const MainContent: FC<MainContentProps> = ({
  isDrawEnabled,
  features,
  mapStyle,
  viewport,
  onMapStyleChange,
  onViewportChange,
  onParadaPosition,
  onParadaSelected,
  selectedParadaId,
  selectedTramIds,
}) => {
  const [cursor, setCursor] = useState<string>('auto');
  const sources = useMemo(() => getSources(isDrawEnabled, features), [features, isDrawEnabled]);
  const layers = useMemo(
    () => getLayers(features, selectedParadaId, selectedTramIds),
    [features, selectedParadaId, selectedTramIds]);

  const handleClick = (event: MapLayerMouseEvent) => {
    if (!isDrawEnabled) {
      const id = event.features?.[0]?.id;
      onParadaSelected(id ? Number(id) : undefined);
    }
  };

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('grab'), []);

  return <>
    <Map
      mapStyle={mapStyle}
      viewport={viewport}
      onViewportChange={onViewportChange}
      sources={sources}
      layers={layers}
      cursor={cursor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      interactiveLayerIds={['parades']}
      onClick={handleClick}
    >
      <BaseMapPicker
        styles={MAPSTYLES} selectedStyleId={mapStyle} onStyleChange={onMapStyleChange} position="top-right"
        direction="down"
      />
      {isDrawEnabled && features &&
      <DrawParadaControl
        snappingData={features}
        maxDistanceInMeters={MAX_DISTANCE_IN_METERS}
        onLocation={onParadaPosition}/>
      }
      { /* TODO remove when integrated with the whole use case */ }
      {/*{features && !isDrawEnabled &&*/}
      {/*  <DrawTramControl*/}
      {/*    snappingData={features}*/}
      {/*    onGeometryDrawn={console.log}/>*/}
      {/*}*/}
    </Map>
  </>;
};

export default MainContent;
