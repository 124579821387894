import Http, {options} from '@/domain/services/Http';
import auth from '@/services/auth';

const keycloakHttp = (baseHttpImpl: Http): Http =>
  async <ResponseType, RequestType> (url: string | URL, options?: options<RequestType>) => {

    const fetchWithToken = async () => {
      await auth.updateToken();
      const accessToken = auth.getToken();
      return baseHttpImpl<ResponseType, RequestType>(url, {
        ...options,
        headers: {
          ...(accessToken ? {'Authorization': `Bearer ${accessToken}`} : {}),
          ...options?.headers
        }
      });
    };

    // TODO TMB-379 this creates and endless loop if the token is invalid (missing api_scopes for example)
    return fetchWithToken();/*.catch(async (e: HttpError) => {
      if (auth.getToken() !== undefined && e.statusCode === 403) {
        // Token is present but may be expired, refresh it and retry.
        await auth.forceNewLogin();
        return await fetchWithToken();
      } else {
        throw(e);
      }
    });*/
  };

export default keycloakHttp;