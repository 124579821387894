import React from 'react';

import CreateParadaIcon from './components/icons/CreateParadaIcon';
import EditParadaIcon from './components/icons/EditParadaIcon';
import MoveParadaIcon from './components/icons/MoveParadaIcon';
import CreateTramIcon from '@/components/icons/CreateTramIcon';

export const RESPONSIVE_HEADER_HEIGHT = 64;
export const RESPONSIVE_HEADER_WIDTH = 380;
export const DRAWER_WIDTH = 440;
export const SM_BREAKPOINT = 600;

export enum Mode {
  VIEW = 'VIEW',
  CREATE_PARADA = 'CREATE_PARADA',
  EDIT_PARADA = 'EDIT_PARADA',
  MOVE_PARADA = 'MOVE_PARADA',
  CREATE_TRAM = 'CREATE_TRAM'
}

export const MINISIDEPANEL_CONFIG = [
  {id: Mode.CREATE_PARADA, route: './map', label: 'Crear Parada', content: <CreateParadaIcon/>, group: 'parada'},
  {id: Mode.EDIT_PARADA, route: './map', label: 'Editar Parada', content: <EditParadaIcon/>, group: 'parada'},
  {id: Mode.MOVE_PARADA, route: './map', label: 'Mover Parada', content: <MoveParadaIcon/>, group: 'parada'},
  {id: Mode.CREATE_TRAM, route: './map', label: 'Crear tramo', content: <CreateTramIcon/>, group: 'tram'},
];

export const INITIAL_VIEWPORT = {
  latitude: 41.4,
  longitude: 2.2,
  zoom: 13,
  bearing: -45,
  pitch: 0
};

export const MAPSTYLES = [
  {
    label: 'PNOA',
    thumbnail: 'mapstyles/i.png',
    id: '/mapstyles/pnoa.json'
  }
];

export const INITIAL_MAPSTYLE_URL = MAPSTYLES[0].id;

export const API_URL = process.env.API_URL;
export const API_DEBOUNCE_MS = parseInt(process.env.API_DEBOUNCE_MS || '200');

// TODO in meters?
export const SNAPPING_CONFIG = {
  maxBboxWidth: 0.01,
  minDistanceToEndpoint: 0.005,
};

export const CODI_POBLACIO = [
  { codi: 742, poblacio: 'Esplugues de Llobregat' },
  { codi: 743, poblacio: 'Sant Adrià de Besòs' },
  { codi: 744, poblacio: 'Sant Just Desvern' },
  { codi: 745, poblacio: 'Badalona' },
  { codi: 746, poblacio: 'Cerdanyola del Vallès' },
  { codi: 747, poblacio: 'Sant Cugat del Vallès' },
  { codi: 748, poblacio: 'Barcelona' },
  { codi: 749, poblacio: 'Cornellà de Llobregat' },
  { codi: 750, poblacio: 'El Prat de Llobregat' },
  { codi: 751, poblacio: 'Santa Coloma de Gramenet' },
  { codi: 752, poblacio: 'Sant Joan Despí' },
  { codi: 753, poblacio: 'Hospitalet de Llobregat' },
  { codi: 754, poblacio: 'Montcada i Reixac' },
  { codi: 755, poblacio: 'Viladecans' }
];

export const CODI_DISTRICT = [
  { id: 621, cityCode: 748, label: 'Sant Martí' },
  { id: 622, cityCode: 748, label: 'Eixample' },
  { id: 623, cityCode: 748, label: 'Sants-Montjuïc' },
  { id: 624, cityCode: 748, label: 'Les Corts' },
  { id: 625, cityCode: 748, label: 'Sarrià-St. Gervasi' },
  { id: 626, cityCode: 748, label: 'Gràcia' },
  { id: 627, cityCode: 748, label: 'Horta-Guinardó' },
  { id: 628, cityCode: 748, label: 'Nou Barris' },
  { id: 629, cityCode: 748, label: 'Sant Andreu' },
  { id: 630, cityCode: 748, label: 'Ciutat Vella' }
];