import {FC} from 'react';
import {AllFeatures} from '@/domain/entities/AllEntities';
import createDrawTramMode, {
  DrawTramOptions,
  EVENT_TRAM_GEOMETRY,
  TramGeometryEvent
} from '@/components/snapping/DrawTramMode';
import {setupDrawControl} from '@/components/draw/setupDrawControl';
import {SNAPPING_CONFIG} from '@/config';

export type DrawTramControlProps = {
  snappingData: AllFeatures,
  onGeometryDrawn: (event: TramGeometryEvent) => void
};

const DrawTramControl: FC<DrawTramControlProps> = ({snappingData, onGeometryDrawn}) =>
  setupDrawControl<DrawTramOptions, TramGeometryEvent>(
    'tram', EVENT_TRAM_GEOMETRY, onGeometryDrawn,
    createDrawTramMode, () => ({
      snappingData: snappingData.trams,
      // TODO config
      maxDistanceToSnap: 0.01,
      ...SNAPPING_CONFIG
    }), [snappingData]);

export default DrawTramControl;


