import {getParades} from '@/domain/usecases/paradaUseCases';
import {getAccesos} from '@/domain/usecases/accesUseCases';
import {getTrams} from '@/domain/usecases/tramUseCases';
import {Dayjs} from 'dayjs';
import {AllEntities} from '@/domain/entities/AllEntities';

const getAllEntities = async (day: Dayjs): Promise<AllEntities> => {
  const [parades, accessos, trams] = await Promise.all([
    getParades(day.toDate()),
    getAccesos(day.toDate()),
    getTrams(day.toDate()),
  ]);
  return {parades, trams, accessos};
};

export default getAllEntities;
