import React, {FC} from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {SvgIconProps} from '@mui/material/SvgIcon/SvgIcon';

const MoveBusStopIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width={48} height={48} viewBox="0 0 24 24" sx={{fontSize: '32px'}}>
    <path d="M20.4 4.8h.8c.5 0 1 .1 1.5.5s.7.9.7 1.5v2.5c0 .5-.3.8-.8.8h-.5c-.4 0-.7-.3-.7-.6V7.7c0-.3.3-.6.6-.6h.2c.1-.7-.2-1.1-.5-1.1h-1.2c-.1 0-.1.1-.1.2V11.6c-.5-.1-1-.1-1.5-.2V7.3c0-.4-.1-.7-.4-1-.2-.2-.5-.2-.7-.2H12.6v6h3.2c-1.2.7-2.1 1.7-2.7 3-.6 1.3-.7 2.6-.4 4H4.1c-.7 0-1-.5-1.1-1V5.9c-.4 0-.8 0-1.1.1-.4.1-.7.5-.6.9h.2c.3 0 .6.3.6.6v1.8c0 .4-.3.7-.7.7H.9c-.4 0-.7-.3-.7-.7V6.5c0-.8.7-1.5 1.5-1.7.2 0 .4 0 .7-.1H3v-.5c.1-1.2.8-2 2-2.5.5-.2 1-.3 1.5-.3h10.4c.8 0 1.6.2 2.2.7.8.6 1.2 1.5 1.3 2.5v.2zM11 6.1H5.8c-.8 0-1.3.4-1.3 1.2v4.8h6.4c.1-2 .1-4 .1-6zm.7-3.2H7.3c-.1 0-.3.1-.4.2-.3.3-.3.6-.2.9s.4.5.7.5H16c.5 0 .8-.3.8-.9 0-.4-.3-.7-.8-.7h-4.3zM7.6 15.7c0-.9-.8-1.5-1.6-1.5-.9 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.9 0 1.7-.6 1.6-1.5zM15.1 22.6H3.8c-.4 0-.6-.2-.6-.6 0-.3.2-.5.5-.5h.8c-.3-.4-.3-.8-.3-1.3h3.5c0 .4 0 .9-.3 1.3h6.4c.1 0 .2 0 .2.1.4.3.8.7 1.1 1z"/>
    <path d="M23.9 17.8c-.1 2.6-2.5 4.8-5.2 4.8-2.7.1-5-2.3-5-5s2.4-5.1 5.2-5c2.6.1 5.1 2.3 5 5.2z"/>
    <path
      d="M15.2 17.5c0-.2.1-.3.2-.4l2.5-2.5c.2-.2.4-.2.6-.1.3.1.4.5.2.8l-.1.1-1.2 1.2c-.2.2-.3.4-.6.5h5c.2 0 .3 0 .4.2.1.2.2.3.1.5s-.2.3-.5.3h-5.1c.1.1.2.1.3.2l.1.1 1.3 1.3c.2.2.2.3.2.6-.1.3-.5.5-.8.3l-.1-.1-2.4-2.4c-.1-.1-.2-.2-.2-.4.1-.1.1-.1.1-.2z"
      style={{fill: '#fcfcfc',}}
    />
  </SvgIcon>
);

export default MoveBusStopIcon;