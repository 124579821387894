import React, {FC} from 'react';

import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';


const StyledDataGrid = styled(DataGrid)(() => ({
  border: 0,
  '& .MuiDataGrid-columnHeaderCheckbox': {
    display: 'none',
  }
}));

type GirSelectorRow = {
  id: string | number
  girId: string | number
  firstTramId: string | number
  secondTramId: string | number
}

export type GirSelectorProps = {
  rows: Array<GirSelectorRow>,
  selectedRowIds: Array<number> | undefined
  onChange: (ids: Array<number>) => void
  onGirHover?: (ids: Array<number>) => void
};

/**
 * Generic gir selector receiving row definitions and handling selections and events with row ids.
 */
const GirSelector: FC<GirSelectorProps> = ({rows, selectedRowIds, onChange, onGirHover}) => {
  const {t} = useTranslation();

  const columns: GridColDef[] = [
    {field: 'girId', headerName: t('girId'), width: 70, headerAlign: 'right', sortable: false},
    {field: 'firstTramId', headerName: t('firstTramId'), headerAlign: 'right', width: 130, sortable: false},
    {field: 'secondTramId', headerName: t('secondTramId'), headerAlign: 'right', width: 130, sortable: false},
  ];
  
  const handleGirHover = (id: number | string) => {
    const hoveredGir = rows.filter(gir => gir.id === id)[0];
    if(!hoveredGir) return;
    
    const girToNumber = (girTram: number | string) => 
      typeof girTram === 'string' ? parseFloat(girTram) : girTram;

    onGirHover && onGirHover([girToNumber(hoveredGir.firstTramId), girToNumber(hoveredGir.secondTramId)]);
  };

  return <Box mb={1.5}>
    <Typography variant='overline' sx={{fontWeight: 'bold'}}>{t('girs')}</Typography>
    <StyledDataGrid
      componentsProps={{
        row: {
          onMouseEnter: e => {
            const id = e?.currentTarget?.dataset?.id;
            const parsedId = id ? parseFloat(id) : NaN;
            if (!isNaN(parsedId)) {
              handleGirHover(parsedId);
            }
          },
          onMouseLeave: () => onGirHover && onGirHover([]),
        }
      }}
      rows={rows}
      columns={columns}
      pageSizeOptions={[5, 10]}
      density='compact'
      hideFooter
      disableColumnMenu
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={ids => onChange(ids.map(Number))}
      rowSelectionModel={selectedRowIds}
    />
  </Box>;
};

export default GirSelector;