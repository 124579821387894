import Http from '@/domain/services/Http';
import {Tram} from '@/domain/entities/Tram';
import {fromDto} from './tramMappers';
import {TramDto} from '@/repository/tram/TramDto';
import {Point} from 'geojson';


export const PATH = 'trams';

const pointToWkt = (point: Point) => `POINT(${point.coordinates[0]} ${point.coordinates[1]})`;

export interface TramRepository {
  list: (params: {
    date?: Date,
    firstCoordinate?: Point,
    lastCoordinate?: Point
  }) => Promise<Array<Tram>>;
}

export const TramRepository = (apiBaseUrl: URL | string, http: Http): TramRepository => ({
  list: async ({date, firstCoordinate, lastCoordinate}) => {
    const url = new URL(PATH, apiBaseUrl);
    if (!date && !firstCoordinate && !lastCoordinate) {
      throw new Error('Either date, firstCoordinate or lastCoordinate must be specified');
    }

    if (date) {
      url.searchParams.set('date', date.toISOString());
    }
    if (firstCoordinate) {
      url.searchParams.set('firstCoordinate', pointToWkt(firstCoordinate));
    }
    if (lastCoordinate) {
      url.searchParams.set('lastCoordinate', pointToWkt(lastCoordinate));
    }

    return http<Array<TramDto>>(url)
      .then(trams => trams.map(fromDto));
  }
});
