import React, {FC, useMemo} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import styled from '@mui/styles/styled';

import GeomaticoLink from '@/components/GeomaticoLink';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import ParadaDetail from '@/components/ParadaDetail';
import {ParadaType} from '@/domain/entities/ParadaType';
import {Gir} from '@/domain/entities/Gir';
import {Mode} from '@/config';
import ModeHeader from '@/components/ModeHeader';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
  padding: '8px'
});

const stackSx = {
  height: '100%',
  overflow: 'hidden'
};

export type SidePanelProps = {
  mode: Mode
  parada: Partial<Parada>
  availableGirs?: Array<Gir>
  paradaTypes?: ParadaType[]
  onParadaChange: (parada: Partial<Parada>) => void
  onParadaSaved: (parada: Parada | ParadaCreation) => void
  onBack: () => void
}

const SidePanelContent: FC<SidePanelProps> = ({
  mode,
  parada,
  availableGirs,
  paradaTypes,
  onParadaChange,
  onParadaSaved,
  onBack
}) => {

  const detailContent = useMemo(() => 
    paradaTypes && showParadaDetail(mode, parada) &&
      <ParadaDetail
        parada={parada}
        girs={availableGirs}
        readOnlyProps={readOnlyProps(mode)}
        readOnlyDates={false}
        titleKey={getTitleKey(mode)}
        availableTypes={paradaTypes}
        onChange={onParadaChange}
        onSave={onParadaSaved}/>, 
  [mode, parada, availableGirs, paradaTypes]);

  return <Stack sx={stackSx}>
    <ScrollableContent>
      <ModeHeader titleKey={mode} showMessage={showInfoMessage(mode, parada)} onBack={onBack}/>
      {detailContent}
    </ScrollableContent>
    <GeomaticoLink/>
  </Stack>;
};

export default SidePanelContent;

const showParadaDetail = (mode: Mode, parada: Partial<Parada>) => {
  switch (mode) {
  case Mode.CREATE_BUS_STOP:
    return true;
  case Mode.EDIT_BUS_STOP:
  case Mode.MOVE_BUS_STOP:
    return 'id' in parada;
  case Mode.VIEW:
  default:
    return false;
  }
};

const readOnlyProps = (mode: Mode) => mode === Mode.MOVE_BUS_STOP;

const showInfoMessage = (mode: Mode, parada: Partial<Parada>) =>
  !('id' in parada) && [Mode.EDIT_BUS_STOP, Mode.MOVE_BUS_STOP].includes(mode);

const getTitleKey = (mode: Mode): string => {
  switch (mode) {
  case Mode.CREATE_BUS_STOP:
    return 'addStop';
  case Mode.EDIT_BUS_STOP:
    return 'editStop';
  case Mode.MOVE_BUS_STOP:
    return 'moveStop';
  case Mode.VIEW:
  default:
    return '';
  }
};
