import {Point} from 'geojson';
import {Gir, GirCreation} from '@/domain/entities/Gir';
import Http from '@/domain/services/Http';
import {GirDto, GirDtoCreation} from '@/repository/gir/girDto';
import {toDto, fromDto} from '@/repository/gir/girMappers';

export const PATH= 'girs';

export interface GirRepository {
  list: (params?: {
    tramIds?: Array<number>,
    pointOfInterest?: Point
  }) => Promise<Array<Gir>>
  create: (gir: GirCreation) => Promise<Gir>
  update: (girId: number, gir: Partial<Gir>) => Promise<Gir>
  delete: (girId: number) => Promise<void>
}

const pointToWkt = (point: Point) => `POINT(${point.coordinates[0]} ${point.coordinates[1]})`;

export const GirRepository = (apiBaseUrl: URL | string, http: Http): GirRepository => ({
  list: async (params) => {
    const url = new URL(PATH, apiBaseUrl);
    if (params?.tramIds && params?.pointOfInterest) {
      throw new Error('Either tram_ids or point_of_interest must be specified');
    }
    if (params?.pointOfInterest) {
      url.searchParams.set('pointOfInterest', pointToWkt(params.pointOfInterest));
    } else if (params?.tramIds) {
      url.searchParams.set('tramId', params.tramIds.join(','));
    }
    return http<Array<GirDto>>(url)
      .then(dtos => dtos.map(dto => fromDto(dto)));
  },
  create: (gir) =>
    http<GirDto, GirDtoCreation>(new URL(PATH, apiBaseUrl), {method: 'POST', body: toDto(gir)})
      .then(dto => fromDto(dto)),
  update: (girId, gir) =>
    http(new URL(`${PATH}/${girId}`, apiBaseUrl), {method: 'PATCH', body: gir}),
  delete: (girId) =>
    http(new URL(`${PATH}/${girId}`, apiBaseUrl), {method: 'DELETE'}),
});