import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import {getUserInformation, JwtPayload} from '@/domain/usecases/getUserInformation';

//TYPES
export type LogoutMenuProps = {
  onLogout: () => void
};

const LogoutMenu: FC<LogoutMenuProps> = ({onLogout}) => {

  const {t} = useTranslation();

  const user: JwtPayload | undefined = getUserInformation();

  return <Box m={1}>
    { 
      user && <>
        <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'center'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Typography variant="body1" component="p" sx={{lineHeight: 1}}>{user.name}</Typography>
            <Typography variant="caption" component="p" sx={{color: 'grey'}}>{user.email}</Typography>
          </Box>
          <Avatar alt={user.name} src={user.name.slice(0, 1)} sx={{border: '1px solid lightgrey', bgcolor:'#e8374c'}}/>
        </Box>
        <Divider flexItem orientation='horizontal' sx={{mx: 0.5, my: 1}} />
      </>
    }
    <Button onClick={onLogout}>{t('logout')}</Button>
  </Box>;
};
export default LogoutMenu;