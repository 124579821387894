import {Tram} from '@/domain/entities/Tram';
import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES} from '@/domain/services/Repository';
import {Point} from 'geojson';
import {TramRepository} from '@/repository/tram/TramRepository';

export const getTrams = async (date: Date): Promise<Array<Tram>> => {
  const repository = inject<TramRepository>(REPOSITORIES.tram);
  return repository.list({date});
};

export const getTramsByFirstCoordinate = async (point: Point): Promise<Array<Tram>> => {
  const repository = inject<TramRepository>(REPOSITORIES.tram);
  return repository.list({firstCoordinate: point});
};

export const getTramsByLastCoordinate = async (point: Point): Promise<Array<Tram>> => {
  const repository = inject<TramRepository>(REPOSITORIES.tram);
  return repository.list({lastCoordinate: point});
};
