import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import Http from '@/domain/services/Http';
import {fromDto, toDto, toDtoCreation} from './paradaMappers';
import {ParadaDto, ParadaDtoCreation} from '@/repository/parada/ParadaDto';
import {GirDto} from '@/repository/gir/GirDto';
import {fromDto as fromGirDto} from '@/repository/gir/girMappers';
import {Gir} from '@/domain/entities/Gir';
import {Repository} from '@/domain/services/Repository';


export const PATH = 'parades';

export const ParadaRepository = (apiBaseUrl: URL | string, http: Http): ParadaRepository => ({
  list: (date: Date) =>
    http<Array<ParadaDto>>(new URL(`${PATH}?date=${date.toISOString()}`, apiBaseUrl))
      .then(parades => parades.map(parada => fromDto(parada))),
  create: (parada: ParadaCreation) =>
    http<ParadaDto, ParadaDtoCreation>(new URL(PATH, apiBaseUrl), {
      method: 'POST',
      body: toDtoCreation(parada)
    })
      .then(dto => fromDto(dto)),
  put: (paradaId: number, parada: Parada) =>
    http<ParadaDto, ParadaDto>(new URL(`${PATH}/${paradaId}`, apiBaseUrl), {
      method: 'PUT',
      body: toDto(parada)
    })
      .then(dto => fromDto(dto)),
  getAvailableGirs: (paradaId: number): Promise<Array<Gir>> =>
    http<Array<GirDto>>(new URL(`${PATH}/${paradaId}/available-girs`, apiBaseUrl))
      .then(girs => girs.map(gir => fromGirDto(gir)))
});

export type ParadaRepository = Repository<Parada, ParadaCreation> & {
  getAvailableGirs: (paradaId: number) => Promise<Array<Gir>>
};
