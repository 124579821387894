import MapboxDraw, {DrawCustomMode} from '@mapbox/mapbox-gl-draw';
import {useEffect} from 'react';
import {MapRef, useControl, useMap} from 'react-map-gl';

/**
 * Utility function to create draw controls. It handles the problems with synchronizing the internal state
 * draw modes hold and the React state, as well as other issues like adding/removing listeners, etc.
 *
 * @param modeName Name of the mode. Must be unique to not conflict with other modes, either custom or from mapbox
 * @param eventName Name of the event triggered by the mode.
 * @param eventListener Listener for the event triggered by the mode.
 * @param createMode Function to create the draw mode. Might want to rely on buildMode in snapping.
 * @param createOptions Function to create the options passed to the mode on setup.
 * @param optionsDependencies (React) dependencies of the mode options. The mode will be setup again with
 * whatever createOptions returns when these dependencies change.
 */
export const setupDrawControl = <TOptions, TEvent>(
  modeName: string,
  eventName: string,
  eventListener: (ev: TEvent) => void,
  createMode: () => DrawCustomMode,
  createOptions: () => TOptions,
  optionsDependencies: unknown[],
) => {
  const {current: map} = useMap();
  const addEventHandlers = (map?: MapRef) => {
    map?.on(eventName, eventListener);
  };

  const removeEventHandlers = (map?: MapRef) => {
    map?.off(eventName, eventListener);
  };

  const resetControl = () => {
    try {
      // changeMode ends up calling <Mode>.onSetup
      // since useControl keeps the control/mode between re-renders, it's the way to pass options and re-initialize
      // the control whenever something else (the data) has changed, without doing weird magic like removing
      // re-adding the control behind the scenes
      control.changeMode(`draw_${modeName}`, createOptions());
    } catch (e) {
      // changeMode might fail if the control is not yet added to the map; just ignore
    }
  };

  const control = useControl<MapboxDraw>(
    () => new MapboxDraw({
      modes: {...MapboxDraw.modes, [`draw_${modeName}`]: createMode()},
      displayControlsDefault: false
    }),
    ({map}) => {
      // on activation
      resetControl();
      addEventHandlers(map);
    },
    ({map}) => removeEventHandlers(map)); // on deactivation

  useEffect(resetControl, optionsDependencies);
  useEffect(() => {
    // ensure we replace events on the map when callbacks are changed
    addEventHandlers(map);
    return () => removeEventHandlers(map);
  }, [eventListener]);

  return null;
};
